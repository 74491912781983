import Vue from 'vue';
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
//import { isInIframe, isWebkit, hasStorageAccess } from '@/plugins/isiniframe'
import { isInIframe, isWebkit, isFirefox } from '@/plugins/isiniframe'
import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'
//import Console from '@/Console'

Vue.use(VueRouter);
Vue.use(VueMeta);


//let object

//const peoMapping = JSON.parse(process.env.VUE_APP_PEO_MAPPING)

export function createRouter(store) {

  const beforeEach = async function(to, from, next) {

    // Assume requiresAuth == true, unless specified false in meta for route
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth ?? true)

    // If visited from ESS, but not explicit sign-out route
    // If it's a sign-out route, do not attempt to process and sign-in with ESS,
    // just display the sign-out route and component

    //if (isInIframe() && isWebkit()) {

    //  // ESS has unusably short iframe window with webkit, so we error out.
    //  to.name == 'not-supported' ? next() : next({name: 'not-supported'})
    //}

    AdvanstaffAPI.setApiUrl(
      'https://api.advanstaff.com',
      //`https://${process.env.VUE_APP_API_BASE}`
      //process.env.VUE_APP_API_BASE,
    )

    const peoId = to.query.peo_id
    const ssoKey = to.query.key

    if (peoId && ssoKey && to.name != 'sign-out') {
    //if (isInIframe() && to.name != 'sign-out') {
      // Get envName from peo id mapping
      //const hostPrefix = peoMapping[peoId]


      //const hostPrefix = window.location.host.replace(
      //  new RegExp(`\\.*?${process.env.VUE_APP_BASE_DOMAIN}`), ''
      //) || peoMapping[process.env.VUE_APP_DEFAULT_PEO]

      //if (!hostPrefix) return _c_onsole.error(`Mapping not found for peo_id (${peoId}). Cannot set envName. Please verify configuration`)

      // setEnvName must happen before setSSO, so that sso is not overwritten
      // setEnvName triggers load of vuex-store from localStorage
      //await store.dispatch('setEnvName', hostPrefix)
      await store.dispatch('Auth/setSSO', {peoId: peoId, ssoKey: ssoKey})

      // In iframe, sign in every time.
      // TODO/FIXME: This seems like a brute force solution
      // Can we avoid this while still being able to switch 
      // between SSO accounts and environments?
      await store.dispatch('Auth/signIn', { peoId: peoId, key: ssoKey })

      return next()
    }

    else {
      // Load persisted state if active unique identifier is set
      const activeUniqueIdentifier = store.getters['Auth/uniqueSignedInIdentifier']
      if (activeUniqueIdentifier) {
        store.dispatch('Auth/setPersistedState', activeUniqueIdentifier)
      }
      const isAuthenticated = store.getters['Auth/isAuthenticated']

      if (requiresAuth) {
        if (isAuthenticated) {
          return next()
        }
        else {
          await store.dispatch('Auth/setRequestedRouteBeforeSignIn', to)

          return next({ name: 'sign-in' })
        }
      }
      else {
        return next()
      }
    }
  }

  let routes = []

  switch (location.hostname) {

    case 'apidocs.advanstaff.com':

      routes.push(
        {
          path: '/signout',
          name: 'sign-out',
          props: true,
          component: () => import('../views/SignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/oauth-signout',
          name: 'oauth-sign-out',
          props: true,
          component: () => import('../views/OAuthSignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/not-supported',
          name: 'not-supported',
          props: true,
          component: () => import('../views/NotSupported.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/signin',
          name: 'sign-in',
          component: () => import('../components/SignIn.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/',
          name: 'apidocs',
          component: () => import('../views/ApiDocs.vue'),
          meta: {
            requiresAuth: true,
          },
        }
      )
      break

    default:

      routes.push(
        {
          path: '/signedin-redirect',
          name: 'signedin-redirect',
          component: () => import('../views/SignedInRedirect.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/confirm-pending-update/:beacon/:data',
          name: 'confirm-pending-update',
          component: () => import('../views/ConfirmPendingUpdate.vue'),
          meta: {
            requiresAuth: false
          },
        },
        //{
        //  path: '/register',
        //  name: 'register',
        //  component: () => import('../views/Register.vue'),
        //  meta: {
        //    requiresAuth: false
        //  },
        //},
        {
          path: '/signout',
          name: 'sign-out',
          props: true,
          component: () => import('../views/SignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/oauth-signout',
          name: 'oauth-sign-out',
          props: true,
          component: () => import('../views/OAuthSignOut.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/not-supported',
          name: 'not-supported',
          props: true,
          component: () => import('../views/NotSupported.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/signin',
          name: 'sign-in',
          component: () => import('../components/SignIn.vue'),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/outbound-sso-choose-actor/:outboundSsoType',
          name: 'outbound-sso-choose-actor',
          props: true,
          component: () => import('../views/OutboundSsoChooseActor.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/',
          name: 'signed-in',
          component: () => import('../views/SignedIn.vue'),
          //beforeEnter: requireAuthentication, // This isn't called on every route for children. Not good for auth that has dynamic functionality, like signout out if received unauthorized from server

          // Only called on this route, not child routes
          // Redirect to outbound-sso if available and domain matches and not in iframe
          //beforeEnter: (to, from, next) => {

          //  // If ess prefix and /, redirect to ess with ess role
          //  if (location.hostname == 'ess.green.advanstaff.com' && !isInIframe() && to.name != 'outbound-sso' && to.path == '/') {
          //    const roles = store.getters['Auth/anyActorOutboundPrismSSORoles']('ess')
          //    if (roles.length == 1) {
          //      next({name: 'outbound-sso', params: { role_id: roles[0].id }, replace: true})
          //      return false
          //    }
          //    else if (roles.length >= 2) {
          //      next({name: 'outbound-sso-choose-actor'})
          //      return false
          //    }
          //    else {
          //      return false
          //    }
          //  }

          //  else if (location.hostname == 'mss.green.advanstaff.com' && !isInIframe() && to.name != 'outbound-sso' && to.path == '/') {
          //    const roles = store.getters['Auth/anyActorOutboundPrismSSORoles']('mss')
          //    if (roles.length >= 1) {
          //      next({name: 'outbound-sso', params: { role_id: roles[0].id }, replace: true})
          //      return false
          //    }
          //    else if (roles.length >= 2) {
          //      next({name: 'outbound-sso-choose-actor'})
          //      return false
          //    }
          //    else {
          //      return false
          //    }
          //  }

          //  else if (to.path == '/') {
          //    next({path: '/personal'})
          //  }

          //  else {
          //    next()
          //  }
          //},

          redirect: '/personal',

          children: [
            // prism sso sshortcuts
            {
              path: '/ess/:employee_id?',
              name: 'ess',
              beforeEnter(to, from, next) {
                if (isInIframe()) return false

                // Get all ess outbound roles, match to employee id, and limit to employee_id if specified in route
                const roles = store.getters['Auth/anyActorOutboundPrismSSORoles']('ess')
                .map(r => {
                  const actorId = r.actor_id
                  r.employee_id = store.getters['Auth/assignedEmployeeForActorId'](actorId)?.employee_id
                  return r
                })
                .filter(r => {
                  const requestedEmployeeId = to.params.employee_id
                  return !requestedEmployeeId || requestedEmployeeId == r.employee_id
                })

                if (roles.length == 1) {
                  next({name: 'outbound-sso', params: { role_id: roles[0].role_id }, replace: true})
                  return false
                }
                else if (roles.length >= 2) {
                  next({name: 'outbound-sso-choose-actor', params: {outboundSsoType: 'ess'}})
                  return false
                }

                next({name: 'not-found', params: { title: 'Permission Not Granted', error: 'Outbound SSO requested (ess), but necessary permissions were not found.' }})

                return false
              },
            },

            {
              path: '/mss/:username?',
              name: 'mss',
              beforeEnter(to, from, next) {
                if (isInIframe()) return false

                const roles = store.getters['Auth/anyActorOutboundPrismSSORoles']('mss')
                .filter(r => !to.params.username || !r.username || to.params.username == r.username)

                if (roles.length == 1) {
                  next({name: 'outbound-sso', params: { role_id: roles[0].role_id }, replace: true})
                  return false
                }
                else if (roles.length >= 2) {
                  next({name: 'outbound-sso-choose-actor', params: {outboundSsoType: 'mss'}})
                  return false
                }

                next({name: 'not-found', params: { title: 'Permission Not Granted', error: 'Outbound SSO requested (mss), but necessary permissions were not found.' }})

                return false
              },
            },

            // zywave sso shortcuts
            {
              path: '/lms/:employee_id?',
              name: 'lms',
              redirect: to => ({ name: 'zywavelms', params: to.params }),
            },

            {
              path: '/zywavelms/:employee_id?',
              name: 'zywavelms',
              //redirect: () => ({name: 'zywave-lms-sso'}),
              beforeEnter(to, from, next) {
                if (isInIframe()) return false

                // Get all ess outbound roles, match to employee id, and limit to employee_id if specified in route
                const roles = store.getters['Auth/anyActorOutboundPrismSSORoles']('zywavelms')
                .map(r => {
                  const actorId = r.actor_id
                  r.employee_id = store.getters['Auth/assignedEmployeeForActorId'](actorId)?.employee_id
                  return r
                })
                .filter(r => {
                  const requestedEmployeeId = to.params.employee_id
                  return !requestedEmployeeId || requestedEmployeeId == r.employee_id
                })

                if (roles.length == 1) {
                  next({name: 'outbound-sso', params: { role_id: roles[0].role_id }, replace: true})
                  return false
                }
                else if (roles.length >= 2) {
                  next({name: 'outbound-sso-choose-actor', params: {outboundSsoType: 'zywavelms'}})
                  return false
                }

                next({name: 'not-found', params: { title: 'Permission Not Granted', error: 'Outbound SSO requested (zywavelms), but necessary permissions were not found.' }})

                return false
              },
            },
            {
              path: '/zywaveportal',
              name: 'zywaveportal',
              beforeEnter(to, from, next) {
                //window.location = process.env.VUE_APP_ZYWAVE_PORTAL_SSO_URL
                //window.location = 'https://aka.zywave.com/zrrae7vwycjygzjw7ur'

                // Get all ess outbound roles, match to employee id, and limit to employee_id if specified in route
                const roles = store.getters['Auth/anyActorOutboundPrismSSORoles']('zywaveportal')
                .map(r => {
                  const actorId = r.actor_id
                  const assignedEmployee = store.getters['Auth/assignedEmployeeForActorId'](actorId)
                  //r.client_id = assignedEmployee?.client_id
                  r.employee_id = assignedEmployee?.employee_id
                  return r
                })
                .filter(r => {
                  const requestedEmployeeId = to.params.employee_id
                  return !requestedEmployeeId || requestedEmployeeId == r.employee_id
                })

                if (roles.length == 1) {
                  next({name: 'outbound-sso', params: { role_id: roles[0].role_id }, replace: true})
                  return false
                }
                else if (roles.length >= 2) {
                  next({name: 'outbound-sso-choose-actor', params: {outboundSsoType: 'zywaveportal'}})
                  return false
                }

                next({name: 'not-found', params: { title: 'Permission Not Granted', error: 'Outbound SSO requested (zywaveportal), but necessary permissions were not found.' }})

                return false
              },
            },

            //{
            //  path: '/zywave/lms/sso',
            //  name: 'zywave-lms-sso',
            //  beforeEnter(to, from, next) {
            //    //window.location = process.env.VUE_APP_ZYWAVE_LMS_SSO_URL
            //    //window.location = 'https://aka.zywave.com/deltz89rvo3uojmriat1'

            //    // Get all ess outbound roles, match to employee id, and limit to employee_id if specified in route
            //    const roles = store.getters['Auth/anyActorOutboundPrismSSORoles']('zywavelms')
            //    .map(r => {
            //      const actorId = r.actor_id
            //      const assignedEmployee = store.getters['Auth/assignedEmployeeForActorId'](actorId)
            //      //r.client_id = assignedEmployee?.client_id
            //      r.employee_id = assignedEmployee?.employee_id
            //      return r
            //    })
            //    .filter(r => {
            //      const requestedEmployeeId = to.params.employee_id
            //      return !requestedEmployeeId || requestedEmployeeId == r.employee_id
            //    })

            //    if (roles.length == 1) {
            //      next({name: 'outbound-sso', params: { role_id: roles[0].role_id }, replace: true})
            //      return false
            //    }
            //    else if (roles.length >= 2) {
            //      next({name: 'outbound-sso-choose-actor', params: {outboundSsoType: 'zywavelms'}})
            //      return false
            //    }

            //    next({name: 'not-found'})
            //    return false
            //  },
            //},

            {
              path: '/zywave/lms/widget',
              name: 'zywave-lms-widget',
              component: () => import('../views/ess-widgets/ZywaveLMS.vue'),
            },
            {
              path: '/posterelite/zip-link/widget',
              name: 'posterelite-zip-link-widget',
              component: () => import('../views/ess-widgets/Posterelite/ZipRedirect.vue'),
            },

            {
              path: '/posterelite/eposters/widget',
              name: 'posterelite-eposters-widget',
              component: () => import('../views/ess-widgets/Posterelite/EPosters.vue'),
            },

            {
              path: '/posterelite/widget-to-iframe',
              name: 'posterelite-widget-to-iframe',
              component: () => import('../views/ess-widgets/Posterelite/WidgetToIframe.vue'),
            },

            // role based sso shortcuts
            {
              path: '/outbound-sso/:role_id',
              name: 'outbound-sso',
              beforeEnter(to) { //, from, next) {
                const actorId = store.getters['Auth/anyActorRoleById'](to.params.role_id)?.actor_id
                if (!actorId) return false

                window.location = `https://api.advanstaff.com/outbound-sso/${to.params.role_id}?actor_id=${actorId}`
              },
            },


            {
              path: '/ping',
              name: 'ping',
              component: () => import('../views/Ping.vue'),
            },
            {
              path: '/dashboard',
              name: 'dashboard',
              component: () => import('../views/Dashboard.vue'),
            },
            {
              path: '/personal',
              name: 'personal',
              component: () => import('../views/Personal.vue'),
            },
            {
              path: '/benefits',
              name: 'benefits',
              component: () => import('../views/Benefits.vue'),
            },
            {
              path: '/pay',
              name: 'pay',
              component: () => import('../views/Pay.vue'),
            },
            {
              path: '/kronos',
              name: 'kronos',
              component: () => import('../views/Kronos.vue'),
              redirect: '/kronos/companies',
              children: [
                {
                  path: 'logs/:job_id',
                  name: 'kronos-job-logs',
                  component: () => import('../components/KronosJobLogs.vue'),
                },
                {
                  path: 'companies',
                  name: 'kronos-companies',
                  component: () => import('../components/KronosCompanies.vue'),
                  children: [
                    {
                      path: 'dashboard',
                      name: 'kronos-dashboard',
                      component: () => import('../components/KronosDashboard.vue'),
                    },
                    {
                      path: ':short_name',
                      name: 'kronos-company',
                      component: () => import('../components/KronosCompany.vue'),
                      redirect: { name: 'kronos-company-config' },
                      children: [
                        {
                          path: 'config',
                          name: 'kronos-company-config',
                          component: () => import('../components/KronosCompanyConfig.vue'),
                        },
                        {
                          path: 'logs',
                          name: 'kronos-company-logs',
                          component: () => import('../components/KronosCompanyLogs.vue'),
                        },
                        {
                          path: 'console',
                          name: 'kronos-company-console',
                          component: () => import('../components/KronosCompanyConsole.vue'),
                        },
                      ],
                    },
                  ]
                }
              ],
            },
            {
              path: '/contacts',
              name: 'contacts',
              component: () => import('../views/Contacts.vue'),
              redirect: '/contacts/clients',

              children: [
                {
                  path: 'clients',
                  name: 'contacts-clients',
                  component: () => import('../components/ContactsClients.vue'),

                  children: [
                    {
                      path: ':env/:client_id',
                      name: 'client-contacts',
                      component: () => import('../components/ClientContacts.vue'),
                      children: [
                        {
                          path: ':contact_id',
                          name: 'client-contact',
                          component: () => import('../components/ClientContact.vue'),

                          children: [
                            {
                              path: ':method_id',
                              name: 'env-client-contact-method',
                              component: () => import('../components/ClientContactMethod.vue'),
                            },
                          ],
                        },
                      ],
                    },
                  ]
                },
                {
                  path: 'methods',
                  name: 'contacts-methods',
                  component: () => import('../components/ContactsMethods.vue'),

                  children: [
                    {
                      path: ':contact_id',
                      name: 'contacts-methods-contact',
                      //component: () => import('../components/ContactsMethod.vue'),
                      //props: route => ({methodId: route.params.method_id, contactId: route.params.contact_id}),
                      component: () => import('../components/ClientContact.vue'),
                    },
                  ]
                },
                {
                  path: 'properties',
                  name: 'contact-properties',
                  component: () => import('../components/ContactsProperties.vue'),

                  children: [
                    {
                      path: ':property_id',
                      name: 'contact-property-methods',
                      component: () => import('../components/ContactPropertyChildren.vue'),
                      children: [
                        {
                          path: ':contact_id',
                          name: 'contact-property-contact',
                          component: () => import('../components/ClientContact.vue'),

                          children: [
                            {
                              path: ':method_id',
                              name: 'contact-propery-contact-method',
                              component: () => import('../components/ClientContactMethod.vue'),
                            },
                          ],
                        },
                      ],
                      //children: [
                      //  {
                      //    path: ':method_id',
                      //    name: 'contact-property-method',
                      //    component: () => import('../components/ContactPropertyMethod.vue'),
                      //  },
                      //]
                    },
                  ]
                },
                {
                  path: ':contact_id',
                  name: 'contact',
                  component: () => import('../components/ClientContact.vue'),

                  children: [
                    {
                      path: ':method_id',
                      name: 'client-contact-method',
                      component: () => import('../components/ClientContactMethod.vue'),
                    },
                  ],
                },
              ],
            },
            {
              path: '/users',
              name: 'users',
              component: () => import('../views/Users.vue'),
              //redirect: { name: 'users-overview' },
              children: [
                {
                  path: 'overview',
                  name: 'users-overview',
                  component: () => import('../components/UsersOverview.vue'),
                },
                {
                  path: 'new',
                  name: 'new-user',
                  component: () => import('../components/NewUser.vue'),
                },
                {
                  path: ':account_id',
                  name: 'user',
                  component: () => import('../components/User2.vue'),
                },
              ],
            },
            {
              path: '/benefit-plan-attributes',
              name: 'benefit-plan-attributes',
              component: () => import('../views/BenefitPlanAttributes.vue'),
              //children: [
              //  {
              //    path: 'logs/:job_id',
              //    name: 'kronos-job-logs',
              //    component: () => import('../components/KronosJobLogs.vue'),
              //  },
              //  {
              //    path: 'companies',
              //    name: 'kronos-companies',
              //    component: () => import('../components/KronosCompanies.vue'),
              //    children: [
              //      {
              //        path: 'dashboard',
              //        name: 'kronos-dashboard',
              //        component: () => import('../components/KronosDashboard.vue'),
              //      },
              //      {
              //        path: ':short_name',
              //        name: 'kronos-company',
              //        component: () => import('../components/KronosCompany.vue'),
              //        redirect: { name: 'kronos-company-config' },
              //        children: [
              //          {
              //            path: 'config',
              //            name: 'kronos-company-config',
              //            component: () => import('../components/KronosCompanyConfig.vue'),
              //          },
              //          {
              //            path: 'logs',
              //            name: 'kronos-company-logs',
              //            component: () => import('../components/KronosCompanyLogs.vue'),
              //          },
              //          {
              //            path: 'console',
              //            name: 'kronos-company-console',
              //            component: () => import('../components/KronosCompanyConsole.vue'),
              //          },
              //        ],
              //      },
              //    ]
              //  }
              //],
            },
            {
              path: 'apidocs',
              name: 'apidocs',
              component: () => import('../views/ApiDocs.vue'),
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'prism-cache',
              name: 'prism-cache',
              component: () => import('../views/PrismCache.vue'),
              redirect: '/prism-cache/clients',
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: 'clients',
                  name: 'prism-cache-clients',
                  component: () => import('../components/PrismCache/Clients.vue'),

                  children: [
                    {
                      path: ':env/:client_id',
                      name: 'prism-cache-client',
                      component: () => import('../components/PrismCache/Client.vue'),

                    },
                  ],
                },
                {
                  path: 'benefits',
                  name: 'prism-cache-benefits',
                  component: () => import('../components/PrismCache/Benefits.vue'),

                  children: [
                    {
                      path: ':benefit_id',
                      name: 'prism-cache-benefit',
                      component: () => import('../components/PrismCache/Benefit.vue'),

                    },
                  ],
                },
                {
                  path: 'users',
                  name: 'prism-cache-users',
                  component: () => import('../components/PrismCache/Users.vue'),

                  children: [
                    {
                      path: ':user_id',
                      name: 'prism-cache-user',
                      component: () => import('../components/PrismCache/User.vue'),

                    },
                  ],
                },
                {
                  path: 'user-roles',
                  name: 'prism-cache-users',
                  component: () => import('../components/PrismCache/UserRoles.vue'),

                  children: [
                    {
                      path: ':user_role_id',
                      name: 'prism-cache-user-roles',
                      component: () => import('../components/PrismCache/UserRole.vue'),

                    },
                  ],
                },
                {
                  path: 'human-resource-roles',
                  name: 'prism-cache-human-resource-roles',
                  component: () => import('../components/PrismCache/HumanResourceRoles.vue'),

                  children: [
                    {
                      path: ':human_resource_role_id',
                      name: 'prism-cache-human-resource-role',
                      component: () => import('../components/PrismCache/HumanResourceRole.vue'),

                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          path: '*',
          name: 'not-found',
          props: true,
          component: () => import('../views/NotFoundComponent.vue'),
        }
      )
  }

  const router = new VueRouter({
    mode: 'history',
    routes: routes
  })

  router.beforeEach(beforeEach)

  const useCookieAuth = !(isInIframe() && (isWebkit() || isFirefox()))
  AdvanstaffAPI.setUseCookieAuth(useCookieAuth)

  // Assign unauthorized error catcher,
  // which will trigger if Advanstaff API returns 401 or 403
  AdvanstaffAPI.setInvalidAuthInterceptor(async ({error}) => {

    // If in iframe, continue, we want a 401 to redirect to sign-out

    // If not in iframe, do not redirect to sign-out if explicitely trying to sign-in
    if (! isInIframe() && error.response.config.url == AdvanstaffAPI.authPath) {
      return
    }

    // sign-out component dispatches Auth/signOut action
    // Why? Because if someone visits sign-out, it needs to go through the proper signOut procedure
    // We're making use of that expected flow here
    router.push( {name: 'sign-out', params: {error: error, requestedUrl: window.location.href}} )
  })

  return router
}
