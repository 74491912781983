//import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'
import Console from '@/Console'

export default (store) => {

  // Only synchronous changes here
  // Async dispatches should not be reactive.

  store.subscribe( async (mutation, state) => {

    if (mutation.type == 'Auth/ACTORID') {
      const actorId   = mutation.payload

      if (! actorId) return

      const user      = state.Auth.user
      const actors    = user.accounts?.flatMap(account => account.actors)
      const accounts  = user.accounts

      const actor     = actors?.find(actor => actor.id == actorId)
      const accountId = actor?.account_id
      const account   = accounts?.find(account => account.id == accountId)

      if (! actor)     return Console.error(`Cannot find ActorId (${actorId}) in list of actors`, actors)
      if (! accountId) return Console.error(`Cannot find AccountId within actor`, actor)
      if (! account)   return Console.error(`Cannot find account for AccountId (${accountId})`)

      store.commit('Auth/ACCOUNTID', accountId, {root: true})

      store.commit('Settings/SET_SETTINGS', {accountId: accountId, actorId: actorId}, {root: true})
    }
  })
}
