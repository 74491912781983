import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'
import _ from 'lodash'

const state = {
  clients: {},
  contactsByEnvAndClientId: {},
  contactsById: {},
  contactsByMethodValue: {},
  contactProperties: {},
  kronosCompanies: {},
  kronosSettings: {},
  kronosJobs: {},
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const getters = {
  actorId: (state, getters, rootState) => rootState.Auth.actorId,

  contactsByEnvAndClientId: (state, getters) => state.contactsByEnvAndClientId[getters.actorId] ?? [],
  contactsByContactId: (state, getters) => state.contactsById[getters.actorId] ?? [],
  contactsByMethodValue: (state, getters) => state.contactsByMethodValue[getters.actorId] ?? {},

  kronosCompanies: (state, getters) => state.kronosCompanies[getters.actorId] ?? [],
  kronosCompanyByShortName: (state, getters) => (shortName) => getters.kronosCompanies.find(c => c.short_name == shortName),
  kronosSettings: (state, getters) => state.kronosSettings[getters.actorId] ?? {},
  kronosJobsByShortName: (state, getters) => (shortName) => state.kronosJobs[getters.actorId]?.filter(j => j.input?.short_name.includes(shortName)) ?? [],

  clients: (state, getters) => state.clients[getters.actorId] ?? [],
  clientByEnvAndId: (state, getters) => (env, clientId) => getters.clients.find(c => c.env == env && c.client_id == clientId),

  contactProperties: (state, getters) => _.sortBy(state.contactProperties[getters.actorId] ?? [], ['name']),
  contactProperty: (state, getters) => propertyIdOrName => state.contactProperties[getters.actorId]?.find(p => p.id == propertyIdOrName || p.name == propertyIdOrName) ?? {},

  clientContactsByEnvAndId: (state, getters) => (env, clientId) => getters.contactsByEnvAndClientId[env + '-' + clientId],
  contactById: (state, getters) => (contactId) => getters.contactsByContactId[contactId],
  contactByMethodValue: (state, getters) => (methodValue) => getters.contactsByMethodValue[methodValue],
  methodByContactIdAndId: (state, getters) => (contactId, methodId) => {
    if (!methodId) { console.trace('methodId not provided') }
    return getters.contactById(contactId)?.methods.find(m => m.id == methodId)
  },

  contactsByPropertyIdOrName: (state, getters) => (propertyIdOrName) => {
    if (!propertyIdOrName) console.trace("propertyId not defined")

    const p = getters.contactProperty(propertyIdOrName)
    if (!p) console.trace('contact property not found', propertyIdOrName)

    if (p.type == 'topic') {
      return Object.values(getters.contactsByMethodValue)
        .filter(c => 
          c.methods.some(m => m.topics.includes(propertyIdOrName))
        )
        .filter(onlyUnique)
    }
    else {
      return Object.values(getters.contactsByMethodValue)
        .filter(c => 
          c.methods.flatMap(m => m.clients)
            .some(c => c.access_levels.includes(propertyIdOrName))
        )
        .filter(onlyUnique)
    }
  },

  methodByContactIdAndMethodId: (state, getters) => (contactId, methodId) => {
    return getters.contactById(contactId)?.methods.find(m => m.id == methodId)
  },

}

const actions = {

  async clientNameSearch({ getters }, search) {
    const currentActorId = getters.actorId
    return AdvanstaffAPI.clientNameSearch(currentActorId, {search})
  },

  async getKronosJobs({ commit, getters }) {
    const currentActorId = getters.actorId

    const now = new Date()
    const tenDaysAgo = new Date(now.setDate(now.getDate() - 10))

    const data = (await AdvanstaffAPI.elasticsearch_jobs(currentActorId, {category: 'kronos_employee_sync', startDate: tenDaysAgo.toISOString()})).data

    if (data) commit('SET_KRONOS_JOBS', {actorId: currentActorId, data})

    return Promise.resolve(data)
  },

  async getKronosJobLogs(_, params) {
    const currentActorId = getters.actorId

    const jobId = params.jobId
    delete params.jobId
    if (!jobId) return Promise.reject('jobId required')

    //const now = new Date()
    //const tenDaysAgo = new Date(now.setDate(now.getDate() - 10))

    //const data = (await AdvanstaffAPI.elasticsearch_jobs(currentActorId, {category: 'kronos_employee_sync', startDate: tenDaysAgo.toISOString()})).data
    const data = (await AdvanstaffAPI.elasticsearch_job_logs(currentActorId,
      {
        category: 'kronos_employee_sync',
        jobId: jobId,
        ...params,
      }
    )).data

    //if (data) commit('SET_KRONOS_JOBS', {actorId: currentActorId, data})

    return Promise.resolve(data)
  },

  async getKronosCompanies({ commit, getters }) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.kronosCompanies(currentActorId)).data

    if (data) commit('SET_KRONOS_COMPANIES', {actorId: currentActorId, data})

    return Promise.resolve(data)
  },

  async getKronosSettings({ commit, getters }) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.kronosSettings(currentActorId)).data

    if (data) commit('SET_KRONOS_SETTINGS', {actorId: currentActorId, data})

    return Promise.resolve(data)
  },

  async contactSearch({ commit, getters }, search) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.contactSearch(currentActorId, {search})).data

    if (data) commit('SET_CONTACT_PROPERTY_METHODS', {actorId: currentActorId, contacts: data})

    return Promise.resolve(data)
  },

  async getClientList({ commit, getters }) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.getClientList(currentActorId)).data

    if (data) commit('SET_CLIENT_LIST', {actorId: currentActorId, clients: data})

    return Promise.resolve(data)
  },

  async getContactProperties({ commit, getters }) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.getContactProperties(currentActorId)).data
    if (data) commit('SET_CONTACT_PROPERTIES', {actorId: currentActorId, properties: data})
    return Promise.resolve()
  },

  async getContactPropertyMethods({ commit, getters }, propertyId) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.getContactPropertyMethods(currentActorId, {propertyId})).data
    if (data) commit('SET_CONTACT_PROPERTY_METHODS', {actorId: currentActorId, contacts: data})
    return Promise.resolve(data)
  },

  async getClientContacts({ commit, getters }, {env, clientId}) {
    const currentActorId = getters.actorId

    let contacts = (await AdvanstaffAPI.clientContacts(currentActorId, {env, clientId})).data

    //// TODO/FIXME: This is bad. We could be talking about 1000s or contacts, or more
    //for (let contact of contacts) {
    //  for (let method of contact.methods) {
    //    method.groups = _.sortBy(method.groups)
    //    method.labels = _.sortBy(method.labels)
    //  }
    //}

    commit('SET_CLIENT_CONTACTS', {actorId: currentActorId, env, clientId, contacts})

    return Promise.resolve(contacts)
  },

  downloadClientContactsCsv({ getters }, {env, clientId}) {
    const currentActorId = getters.actorId

    return AdvanstaffAPI.clientContactsCsv(currentActorId, {env, clientId})

    .then(response => {
      const filename = `${env}-${clientId}.csv`
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  },

  saveKronosCompany({ commit, getters }, company) {
    const currentActorId = getters.actorId

    return AdvanstaffAPI.saveKronosCompany(currentActorId, {company})

    .then(res => {
      commit('SET_KRONOS_COMPANY', {actorId: currentActorId, data: res.data})

      return company
    })
  },

  saveKronosSettings({ commit, getters }, settings) {
    const currentActorId = getters.actorId

    return AdvanstaffAPI.saveKronosSettings(currentActorId, {settings})

    .then(res => {
      commit('SET_KRONOS_SETTINGS', {actorId: currentActorId, data: res.data})

      return res.data
    })
  },

  queueKronosSync({ getters }, {shortName, employeeIds}) {
    const currentActorId = getters.actorId

    return AdvanstaffAPI.queueKronosSync(currentActorId, {shortName, employeeIds})
  },

  saveContact({ commit, getters }, { contact }) {
    const currentActorId = getters.actorId

    return AdvanstaffAPI.saveContact(currentActorId, {contact})

    .then(res => {
      commit('SET_CONTACT', {actorId: currentActorId, contact: res.data})

      return contact
    })
  },

  async addContact({ commit, getters }, contact) {
    const currentActorId = getters.actorId

    const addedContact = (await AdvanstaffAPI.addContact(currentActorId, {contact})).data

    commit('SET_CONTACT', {actorId: currentActorId, contact: addedContact})

    return Promise.resolve(addedContact)
  },

  async removeContact({ commit, getters }, { contactId }) {
    const currentActorId = getters.actorId

    const removedContact = await AdvanstaffAPI.removeContact(currentActorId, {contactId})

    commit('REMOVE_CONTACT', {actorId: currentActorId, contactId, contact: getters.contactById(contactId)})

    return Promise.resolve(removedContact)
  },

  clearState: ({ commit }) => {

    commit('CLEAR_STATE')

    return Promise.resolve()
  }
}

const mutations = {

  SET_CLIENT_CONTACTS(state, {actorId, env, clientId, contacts}) {

    // In array under object keyed by env + '-' + clientId
    const key = env + '-' + clientId
    const actorContactsByEnvAndClientId = state.contactsByEnvAndClientId[actorId] || {}
    actorContactsByEnvAndClientId[key] = contacts
    state.contactsByEnvAndClientId = { ...state.contactsByEnvAndClientId, [actorId]: actorContactsByEnvAndClientId }

    // In object keyed by contactId
    const actorContactsById = Object.fromEntries(
      contacts.map(c => [c.id, c])
    )
    state.contactsById = { ...state.contactsById, [actorId]: actorContactsById }

    // In object keyed by method value
    const actorContactsByMethodValue =
      contacts
      .reduce(
        (acc, contact) => {
          for (const methodValue of contact.methods.map(m => m.value)) {
            acc[methodValue] = contact
          }
          return acc
        },
        {}
      )
    state.contactsByMethodValue = { ...state.contactsByMethodValue, [actorId]: actorContactsByMethodValue }
  },

  SET_CONTACT_PROPERTY_METHODS(state, {actorId, contacts}) {

    //// In array under object keyed by env + '-' + clientId
    //const key = env + '-' + clientId
    //const actorContactsByEnvAndClientId = state.contactsByEnvAndClientId[actorId] || {}
    //actorContactsByEnvAndClientId[key] = contacts
    //state.contactsByEnvAndClientId = { ...state.contactsByEnvAndClientId, [actorId]: actorContactsByEnvAndClientId }

    // In object keyed by contactId
    const actorContactsById = Object.fromEntries(
      contacts.map(c => [c.id, c])
    )
    state.contactsById = { ...state.contactsById, [actorId]: actorContactsById }

    // In object keyed by method value
    const actorContactsByMethodValue =
      contacts
      .reduce(
        (acc, contact) => {
          for (const methodValue of contact.methods.map(m => m.value)) {
            acc[methodValue] = contact
          }
          return acc
        },
        {}
      )
    state.contactsByMethodValue = { ...state.contactsByMethodValue, [actorId]: actorContactsByMethodValue }
  },

  //SET_CLIENT_CONTACT(state, {actorId, env, clientId, contact}) {
  //  const key = env + '-' + clientId
  //  const actorContacts = state.contacts[actorId] || {}
  //  const clientContacts = actorContacts[key] || []
  //  const foundContactIndex = clientContacts.findIndex(c => c.id == contact.id)

  //  if (foundContactIndex == -1) {
  //    clientContacts.push(contact)
  //  }
  //  else {
  //    clientContacts[foundContactIndex] = contact
  //  }

  //  actorContacts[key] = clientContacts

  //  state.contacts = { ...state.contacts, [actorId]: actorContacts }
  //},

  //SET_CONTACTS(state, {actorId, contacts}) {
  //  debugger
  //  // Objects in array under client id
  //  const actorContactsByClientId = state.contactsByClientId[actorId] || {}
  //  actorContactsByClientId[clientId.toString()] = contacts
  //  state.contactsByClientId = { ...state.contactsByClientId, [actorId]: actorContactsByClientId }

  //  // And in object by contact id
  //  const actorContactsById = Object.fromEntries(
  //    Object.values(actorContactsByClientId)
  //    .flatMap(c => c)
  //    .map(c => [c.id, c])
  //  )

  //  state.contactsById = { ...state.contactsById, [actorId]: actorContactsById }

  //  // And in object by method value
  //  const actorContactsByMethodValue = Object.fromEntries(
  //    Object.values(actorContactsById)
  //    .flatMap(c => c.methods)
  //    .map(m => [m.value, m.contact_id])
  //  )

  //  state.contactsByMethodValue = { ...state.contactsByMethodValue, [actorId]: actorContactsByMethodValue }
  //},

  SET_CONTACT(state, {actorId, contact}) {

    // In array under object keyed by env + '-' + clientId
    const byEnvAndClientId = contact.methods.flatMap(m => m.clients).reduce(
      (acc, client) => {
        const key = client.env + '-' + client.client_id
        acc[key] ||= []
        acc[key].push(contact)
        return acc
      },
      {}
    )
    let actorContactsByEnvAndClientId = state.contactsByEnvAndClientId[actorId] || {}
    for (const key of Object.keys(byEnvAndClientId)) {
      actorContactsByEnvAndClientId[key] = _.unionBy(byEnvAndClientId[key], actorContactsByEnvAndClientId[key], "id")
    }
    state.contactsByEnvAndClientId = { ...state.contactsByEnvAndClientId, [actorId]: actorContactsByEnvAndClientId }

    // In object keyed by contactId
    const actorContactsById = state.contactsById[actorId] || {}
    actorContactsById[contact.id] = contact
    state.contactsById = { ...state.contactsById, [actorId]: actorContactsById }

    // In object keyed by method value
    const actorContactsByMethodValue = state.contactsByMethodValue[actorId] || {}
    const methodValues = contact.methods.map(m => m.value)
    for (const methodValue of methodValues) {
      actorContactsByMethodValue[methodValue] = contact
    }
    state.contactsByMethodValue = { ...state.contactsByMethodValue, [actorId]: actorContactsByMethodValue }
  },

  SET_CLIENT_LIST(state, {actorId, clients}) {
    state.clients = { ...state.clients, [actorId]: clients }
  },

  SET_CONTACT_PROPERTIES(state, {actorId, properties}) {
    state.contactProperties = { ...state.contactProperties, [actorId]: properties }
  },

  SET_KRONOS_COMPANIES(state, {actorId, data}) {
    state.kronosCompanies = { ...state.kronosCompanies, [actorId]: data }
  },

  SET_KRONOS_COMPANY(state, {actorId, data}) {
    const actorKronosCompanies = state.kronosCompanies[actorId]
    const foundIndex = actorKronosCompanies.findIndex(c => c.kronos_id == data.kronos_id)
    if (foundIndex > -1) {
      actorKronosCompanies.splice(foundIndex, 1, data)
    }
    state.kronosCompanies = { ...state.kronosCompanies, [actorId]: actorKronosCompanies }
  },

  SET_KRONOS_SETTINGS(state, {actorId, data}) {
    state.kronosSettings = { ...state.kronosSettings, [actorId]: data }
  },

  SET_KRONOS_JOBS(state, {actorId, data}) {
    state.kronosJobs = { ...state.kronosJobs, [actorId]: data }
  },

  REMOVE_KRONOS_COMPANIES(state, {actorId}) {
    state.kronosCompanies[actorId] = {}
  },

  REMOVE_KRONOS_SETTINGS(state, {actorId}) {
    state.kronosSettings[actorId] = {}
  },

  REMOVE_CLIENT_LIST(state, actorId) {
    state.clients[actorId] = {}
  },

  REMOVE_CONTACTS(state, actorId) {
    state.contactsByEnvAndClientId[actorId] = {}
    state.contactsById[actorId] = {}
    state.contactsByMethodValue[actorId] = {}
  },

  REMOVE_CONTACT(state, {actorId, contactId, contact}) {

    if (contact) {
      const clients = contact?.methods?.flatMap(m => m.clients) ?? []

      for (const client of clients) {
        const env = client.env
        const clientId = client.client_id

        // Remove from array under object keyed by env + '-' + clientId
        const key = env + '-' + clientId
        const actorContactsByEnvAndClientId = state.contactsByEnvAndClientId[actorId] || {}
        const clientContacts = actorContactsByEnvAndClientId[key]
        const foundContactIndex = clientContacts.findIndex(c => c.id == contactId)
        if (foundContactIndex > -1) {
          clientContacts.splice(foundContactIndex, 1)
        }
        actorContactsByEnvAndClientId[key] = clientContacts
        state.contactsByEnvAndClientId = { ...state.contacts, [actorId]: actorContactsByEnvAndClientId }
      }

      // Remove object keyed by method value
      const actorContactsByMethodValue = state.contactsByMethodValue[actorId] || {}
      for (const methodValue of contact.methods.map(m => m.value)) {
        delete actorContactsByMethodValue[methodValue]
      }
      state.contactsByMethodValue = { ...state.contactsBymethodValue, [actorId]: actorContactsByMethodValue }
    }

    // Remove object keyed by contactId
    const actorContactsById = state.contactsById[actorId] || {}
    delete actorContactsById[contactId]
    state.contactsById = { ...state.contactsById, [actorId]: actorContactsById }
  },

  REMOVE_CONTACT_LISTS(state, actorId) {
    state.contactProperties[actorId] = {}
  },

  CLEAR_STATE(state) {
    state.clients = {}
    state.contactsByEnvAndClientId = {}
    state.contactsById = {}
    state.contactsByMethodValue = {}
    state.contactProperties = {}
    state.kronosCompanies = {}
    state.kronosSettings = {}
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
