import _ from 'lodash'
import * as msal from "@azure/msal-browser"
//import { createRouter }  from '@/router/index.js'
import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'
import createPersistedState from 'vuex-persistedstate'
import Constants from '@/constants.js'
import Console from '@/Console'
import posthog from 'posthog-js'
//import _ from 'lodash'

const UNIQUE_SIGNED_IN_IDENTIFIER_KEY = 'UNIQUE_SIGNED_IN_IDENTIFIER'

const state = {

  // Signed-in user object
  user: undefined,

  // Currently selected user states
  accountId: undefined,
  actorId: undefined,
  identityId: undefined,
  roleNames: {},

  // PrismHR SSO
  peoId: undefined,
  ssoKey: undefined,

  requestedRouteBeforeSignIn: undefined,

  // Registration
  foundEmployeeDigests: [],
  takenUsernames: {},
  signoutTriggered: false,

  loggedInWithGoogle: false,
  loggedInWithMicrosoft: false,
  loggedInWithPassword: false,
  loggedInWithPrism: false,

  loggedInMicrosoftUsername: undefined,
}

const getters = {

  user: (state) => state.user,

  actors: (state, getters) => (getters.user.accounts || []).flatMap(a => a.actors),

  // Employees for all accounts and actors
  // For employees specific to an account, see Account module
  // For employees specific to an actor, see Actor module
  employees: (state) => {
    const accounts = state.user?.accounts || []
    const employees = accounts 
    .flatMap(account => account.actors)
    .flatMap(actor => actor.roles)
    .filter(role => role[1] == 'assigned-employee')
    .map(array => ({..._.fromPairs(_.chunk(array, 2)), role_id: array[0], name: array[1]}))
    return employees
  },

  assignedEmployeeForActorId: (state, getters) => (actorId) => getters.employees.find(e => e.actor_id == actorId),

  assignedMssUsernameForActorId: state => (actorId) => {
    return state.user?.accounts
      .flatMap(account => account.actors)
      .filter(actor => actor.id == actorId)
      .flatMap(actor => actor.roles)
      .filter(role => role[1] == 'assigned-mss-username')
      .map(array => ({..._.fromPairs(_.chunk(array, 2))}))
      .map(r => r.username)
      .find()
  },

  requestedRouteBeforeSignIn: (state) => state.requestedRouteBeforeSignIn,

  // Current user state helpers

  roleNames: (state, getters) => state.roleNames[getters.actorId] ?? [],

  // Permission helpers

  canManageKronos: function(state, getters, rootState, rootGetters) {
    return rootGetters['User/roleNames'].some(roleName => roleName == Constants.roleNames.kronos)
  },

  canManageUsers: function(state, getters, rootState, rootGetters) {
    return rootGetters['User/roleNames'].some(roleName => roleName == Constants.roleNames.manageUser)
  },

  canManageContacts: function(state, getters, rootState, rootGetters) {
    return rootGetters['User/roleNames'].some(roleName => roleName == Constants.roleNames.manageContacts)
  },

  canAccessApiSpec: function(state, getters, rootState, rootGetters) {
    return rootGetters['User/roleNames'].some(roleName => roleName == Constants.roleNames.apiSpec)
  },

  canAccessPrismCache: function(state, getters, rootState, rootGetters) {
    return rootGetters['User/roleNames'].some(roleName => roleName == Constants.roleNames.prismCache)
  },

  allActorOutboundSsoRolesWithEmployee: (state, getters) => (referencedId) =>
    getters.anyActorOutboundPrismSSORoles(referencedId)
    .map(r =>
      r.client_id && r.employee_id
        ? {...r} // explicit defined in values
        : {...getters.assignedEmployeeForActorId(r.actor_id), ...r} // implicit pulled from assigned-employee role
    ),

  anyActorRoleById: (state) => (roleId) => {
    const role = (state.user?.accounts || [])
    .flatMap(account => account.actors)
    .flatMap(actor => actor.roles)
    .map(array => ({..._.fromPairs(_.chunk(array, 2)), role_id: array[0], name: array[1]}))
    .find(role => role.role_id == roleId)
    return role
  },

  hasAnyActorOutboundPrismSSO: function(state, getters) {
    return (getters.anyActorOutboundPrismSSORoles('ess').length + getters.anyActorOutboundPrismSSORoles('prismhr').length) > 0
  },

  anyActorOutboundPrismSSORoles: (state, getters) => (referenced_id) => {
    const roles = (getters.user?.accounts || [])
      ?.flatMap(account => account.actors)
      ?.flatMap(actor => actor.roles)
      ?.filter(role_values => role_values[1] == Constants.roleNames.outboundSSO)
      .map(array => ({..._.fromPairs(_.chunk(array, 2)), role_id: array[0], name: array[1]}))
      .filter(role => role.referenced_id == referenced_id)

      //console.log({here: (getters.user?.accounts || [])
      //?.flatMap(account => account.actors)
      //?.flatMap(actor => actor.roles)})

    return roles
  },

  hasCurrentActorOutboundPrismSSO: function(state, getters) {
    return (getters.currentActorOutboundPrismSSORoles('ess').length + getters.currentActorOutboundPrismSSORoles('prismhr').length) > 0
  },

  currentActorOutboundPrismSSORoles: (state, getters, rootState, rootGetters) => (referenced_id) => {
    const roles = rootGetters['Actor/roles'] || []

    return roles
    .map(r => {
      if (r[1] != Constants.roleNames.outboundSSO) return undefined

      let role = Object.fromEntries(_.chunk(r, 2))
      role.id = r[0]
      role.name = r[1]

      return role.referenced_id == referenced_id
      ? role
      : undefined
    })
    .filter(r => r)
  },

  isAuthenticated: function(state) {
    // if (window.self !== window.top) {
    // }
    // else {
    // }

    return window.self !== window.top
    ? !!state.user?.prism_sso?.user_id && !!state.actorId
    : !!state.user?.id
  },

  emailAddressFromFoundEmployeeDigests (state) {
    return state.foundEmployeeDigests.find(digestObject => digestObject.email)?.email
  },

  isUsernameAvailable: state => v => {
    return !state.takenUsernames[v]
  },

  uniqueSignedInIdentifier() {
    try {
      return localStorage.getItem(UNIQUE_SIGNED_IN_IDENTIFIER_KEY)
    }
    catch (error) {
      console.warn('Could not getItem from localStorage. Are you in incognito mode?', error)
      return undefined
    }
  }
}

const actions = {

  setSSO({ commit }, { peoId, ssoKey } ) {
    commit('PEOID', peoId)
    commit('SSOKEY', ssoKey)

    return Promise.resolve()
  },

  async setActorId({ commit, dispatch }, actorId) {
    commit('ACTORID', actorId)

    await dispatch('Settings/saveSettingsToServer', undefined, {root: true})
  },

  setRequestedRouteBeforeSignIn( {commit}, route ) {
    commit('SET_REQUESTED_ROUTE_BEFORE_SIGNIN', route)
  },

  removeRequestedRouteBeforeSignIn({commit}) {
    commit('REMOVE_REQUESTED_ROUTE_BEFORE_SIGNIN')
  },

  async setPersistedState ({commit, dispatch}, {uniqueIdentifier, user}) {

    const key = uniqueIdentifier
    //Console.log(`Loading localStorage (${key}) into vuex store`)

    try {
      createPersistedState({
        key: key, 

        // if user specified, replace rehydrated user with new user
        rehydrated: () => { if (user) commit('USER', user) }
      })(this)

      await dispatch('setPosthogIdentity')
    }
    catch (error) {
      console.warn('Could not create persisted state. Are you in incognito mode?', error)
    }

    //const persistedActorId = this.state.actorId

    //AdvanstaffAPI.setActorId(persistedActorId)

    return Promise.resolve()
  },

  async confirmEmployeeRegistration(n, name) {
    return (await AdvanstaffAPI.confirmEmployeeRegistration(name)).data
  },

  async verifyEmployeeRegistrationConfirmationCode(n, code) {
    return await AdvanstaffAPI.verifyEmployeeRegistrationConfirmationCode(code)
  },

  async findEmployeeDigests ( { commit }, identifiers ) {

    try {
      const foundEmployeeDigests = await AdvanstaffAPI.findEmployeeDigests(identifiers)
      commit('SET_FOUND_EMPLOYEE_DIGESTS', foundEmployeeDigests.data)
      return foundEmployeeDigests
    }
    catch(error) {
      Console.error('Could not find employee digests using identifiers', identifiers)
      throw error
    }
  },

  async isUsernameTaken ( { commit }, username ) {

    try {
      await AdvanstaffAPI.findUsername(username)
      Console.error('Username is taken', username)
      commit('SET_TAKEN_USERNAME', username)
      return true
    }
    catch(error) {
      Console.log('Username is available', username)
      commit('REMOVE_TAKEN_USERNAME', username)
      return false
    }
  },

  async signIn ({ commit, dispatch }, userOrCredentials) {

    try {
      const user = userOrCredentials.id && userOrCredentials.accounts
        ?  userOrCredentials
        : (await AdvanstaffAPI.postAuth(userOrCredentials))?.data

      commit('USER', user)

      let accountId
      let actorId
      let identityId = user.id
      // let username = `${user.identity_type}/${user.username}`

      // If authenticated via mss or ess outbound sso, we know the actor and assigned-employee to assign
      if (user.identity_type == 'mss_user_id' || user.identity_type == 'ess_user_id') {
        const role = user.accounts
          ?.flatMap(account => account.actors)
          ?.flatMap(actor => actor.roles)
          ?.filter(role_values => role_values[1] == 'assigned-employee')
          ?.map(array => _.fromPairs(_.chunk(array, 2)))
          ?.first()

        if (!role) return Promise.reject(`assigned-employee role not found`)

        Console.log(`Assigned AccountId and ActorId from ${user.identity_type} authentication`)

        accountId = role.account_id
        actorId = role.actor_id
      }

      // Direct green login - not assigned from sso, or prism sso is broken

      if (!accountId || !actorId) {

        let settings

        try {
          // Assign pre-selected accountId and actorId from server-stored settings
          // But only if user has access to them
          //settings = (await dispatch('Settings/getSettings', undefined, { root: true })) ?? {}
          accountId = user.accounts?.find(a => a.id == settings.accountId)?.id
          actorId = user.accounts?.flatMap(a => a.actors).find(act => act.id == settings.actorId)?.id
        }
        catch (err) {
          Console.log(`No settings for ${this.$appName} found on server`)
        }

        // If not from prism or settings,
        // assign accountId and actorId from first in list in alpha order
        accountId ??= user.accounts?.map(account => account.id).sort()[0]
        actorId ??= user.accounts?.find(account => account.id == accountId)
          ?.actors
          ?.map(actor => actor.id)
          ?.sort()[0]

        if (!accountId) throw 'Could not assign AccountId from sso, settings or accounts list in user'
        if (!actorId)   throw 'Could not assign ActorId from sso, settings or actor list in user'
      }

      // vuex state will be saved to localStorage, separate for each IdentityId
      await dispatch('setUniqueSignedInIdentifier', identityId)

      // Rehydrated state from localStorage, replace stored user with new user
      await dispatch('setPersistedState', {uniqueIdentifier: identityId, user})

      commit('IDENTITYID', identityId)

      // Initiates ActiveActor plugin, which sets settings and account id from new state.user
      commit('ACTORID', actorId)

      //const assignedEmployeeRole = user.accounts
      //  ?.flatMap(account => account.actors)
      //  ?.flatMap(actor => actor.roles)
      //  ?.filter(role_values => role_values[1] == 'assigned-employee')
      //  ?.map(array => _.fromPairs(_.chunk(array, 2)))
      //  ?.first()

      //const clientId = assignedEmployeeRole?.client_id
      //const employeeId = assignedEmployeeRole?.employee_id
      //const clientName = assignedEmployeeRole?.client_name
      //const env = assignedEmployeeRole?.env

      //const friendlyName = employeeId
      //  ? `${identityId}/${env}/${clientId}/${employeeId}`
      //  : `${identityId}/${username}`

      //posthog.register({prismEnv: env})

      //posthog.identify(
      //  identityId,
      //  {identityId, actorId, accountId, clientId, employeeId, clientName, username, friendlyName},
      //)

      //AdvanstaffAPI.setActorId(actorId)
      return Promise.resolve()
    }

    catch(error) {
      Console.error('Could not signIn; explicitely signing out.', error)

      await dispatch('signOut')

      throw error
    }
  },

  async googleOAuth2SignIn({ commit }, $gAuth) {
    const googleUser = await $gAuth.signIn()
    // https://developers.google.com/identity/sign-in/web/reference#googleusergetbasicprofile

    commit('SET_LOGGED_IN_WITH_GOOGLE', true)

    return Promise.resolve({
      token: googleUser.getAuthResponse().access_token,
      //userId: googleUser.getId(), // permanent static id for user
      email: googleUser.getBasicProfile().getEmail()
    })
  },

  async googleOAuth2SignOut({ commit }, $gAuth) {

    const gAuth = this._vm.$gAuth

    if (! gAuth) {
      const error = 'gAuth object not available when attempting to sign out of google'
      Console.error(error)
      return Promise.reject(error)
    }

    const promise = await $gAuth.signOut()
    commit('SET_LOGGED_IN_WITH_GOOGLE', false)

    Console.log('Signed out of google')

    return promise
  },

  async microsoftOAuth2IsSignedIn(obj, microsoftUser) {

    // Config object to be passed to Msal on creation.
    // For a full list of msal.js configuration parameters,
    // visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html

    const msalConfig = {
      auth: {
        clientId: "ee86b1b3-6bac-4d96-b50e-af6e0713d6b9",
        authority: "https://login.microsoftonline.com/common",
        //redirectUri: redirectUri,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    }

    const msalInstance = new msal.PublicClientApplication(msalConfig);

    //await msalInstance.getAllAccounts()
    const account = await msalInstance.getAccountByUsername(microsoftUser?.email)

    return account ? microsoftUser : null
  },

  async microsoftOAuth2SignIn({ commit }, { redirectUri} ) {

    // Config object to be passed to Msal on creation.
    // For a full list of msal.js configuration parameters,
    // visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html

    const msalConfig = {
      auth: {
        clientId: "ee86b1b3-6bac-4d96-b50e-af6e0713d6b9",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: redirectUri,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    }

    const msalInstance = new msal.PublicClientApplication(msalConfig);

    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    const loginRequest = {
      scopes: ["openid", "email", "profile", "user.read"],
      prompt: "select_account",
    };

    const loginResponse = await msalInstance.loginPopup(loginRequest)

    //let loginResponse

    //loginResponse = await msalInstance.acquireTokenSilent(msalConfig)
    //.then(r => loginResponse = r)
    //.catch(() => {
    //  Console.log('msal silent token not available')
    //})

    //if (!loginResponse) {
      //loginResponse = await msalInstance.loginPopup(loginRequest)
    //}

    //if (!loginResponse) {
    //  await msalInstance.loginPopup(loginRequest)
    //  .then(r => {
    //    console.log('ok')
    //    loginResponse = r
    //  })
    //  .catch(error => {
    //    console.log('hootc', error)
    //    //console.error(error)
    //  })
    //}

    //const myAccounts = msalInstance.getAllAccounts();

    const username = loginResponse.account?.username
    const token = loginResponse.idToken

    if (!username) Console.error('username not found in microsoft login response')
    if (!token)    Console.error('rawIdToken not found in microsoft login response')

    commit('SET_LOGGED_IN_WITH_MICROSOFT_USERNAME', username)

    return Promise.resolve({
      token: token,
      email: username,
      //userId: loginResponse.account?.accountIdentifier, // permanent static id for user
    })
  },

  //async microsoftOAuth2SignOut({ state, commit }, options ) {

  //  const router = createRouter()

  //  if (!options) {
  //    Console.error('microsoftOAuth2SignOut called without redirect URIs')
  //    options = {}
  //  }

  //  const loggedInMicrosoftUsername = state.loggedInMicrosoftUsername
  //  const signedInRedirectUri  = options.signedInRedirectUri
  //  const signedOutRedirectUri = options.signedOutRedirectUri
  //    ?? options.signedOutRedirectRouteName
  //      ? window.location.origin + router.resolve({ name: options.signedOutRedirectRouteName }).href
  //      : null

  //  if (! signedOutRedirectUri) {
  //    Console.error('Could not determine signedOutRedirectUri when attempting to sign out of microsoft')
  //  }

  //  const msalConfig = {
  //    auth: {
  //      clientId: "ee86b1b3-6bac-4d96-b50e-af6e0713d6b9",
  //      redirectUri: signedInRedirectUri ?? null,
  //      //postLogoutRedirectUri: window.location.origin + router.resolve({ name: 'oauth-sign-out' }).href,
  //      postLogoutRedirectUri: signedOutRedirectUri ?? null,
  //    },
  //    //cache: {
  //    //  cacheLocation: "sessionStorage", // This configures where your cache will be stored
  //    //  storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  //    //}
  //  }

  //  const myMsal = new msal.PublicClientApplication(msalConfig)

  //  //const logoutRequest = {
  //    //account: myMsal.getAccountByUsername(loggedInMicrosoftUsername)
  //  //}

  //  return myMsal.logout() //logoutRequest)
  //  .then(() => {
  //    commit('SET_LOGGED_IN_WITH_MICROSOFT_USERNAME', undefined)
  //    Console.log('Signed out of microsoft')
  //  })
  //},

  async setUniqueSignedInIdentifier ( n, uniqueIdentifier ) {
    try {
      localStorage.setItem(UNIQUE_SIGNED_IN_IDENTIFIER_KEY, uniqueIdentifier)
    }
    catch (error) {
      console.warn('Could not setItem in localStorage. Are you in incognito mode?', error)
    }

    return Promise.resolve()
  },

  async clearUniqueSignedInIdentifier() {
    try {
      localStorage.removeItem(UNIQUE_SIGNED_IN_IDENTIFIER_KEY)
    }
    catch (error) {
      console.warn('Could not removeItem from localStorage. Are you in incognito mode?', error)
    }

    return Promise.resolve()
  },

  async createUser ( n, options ) {
    const identityTokens = [
      options.googleUserToken ? { type: 'oauth2_jwt', provider: 'google', token: options.googleUserToken } : undefined,
      options.microsoftUserToken ? { type: 'oauth2_jwt', provider: 'microsoft', token: options.microsoftUserToken } : undefined,
      options.appleUserToken ? { type: 'oauth2_jwt', provider: 'apple', token: options.appleUserToken } : undefined,
      options.password ? { type: 'password', username: options.username, password: options.password } : undefined,
    ].filter(item => item !== undefined)

    return AdvanstaffAPI.createUser({
      identityTokens: identityTokens,
      email: options.email,
    })
  },

  async signOut ( { state, commit, dispatch } ) {

    // Signout is triggered by 401 response from Advanstaff API
    // Do not signOut from AdvanstaffAPI again if we've already done it

    Console.log('HAS SIGNOUT ALREADY BEEN triggered?', state.signoutTriggered)

    if (!state.signoutTriggered) {
      AdvanstaffAPI.signOut()
    }

    posthog.reset()

    commit('SET_SIGNOUT_TRIGGERED', true) // Set signoutForced to true

    await dispatch('clearState', undefined, {root: true})

    //AdvanstaffAPI.setActorId(undefined)

    // Clear all local storage
    try {
      localStorage.clear()
    }
    catch (error) {
      console.warn('Could not clear localStorage. Are you in incognito mode?', error)
    }

    if (state.loggedInWithGoogle) {
      dispatch('googleOAuth2SignOut')
    }

    //if(state.loggedInWithMicrosoft) {
      //dispatch('microsoftOAuth2SignOut', {
      //  signedOutRedirectRouteName: 'sign-in'
      //})
    //}

    if (state.loggedInWithPassword) {
      commit('SET_LOGGED_IN_WITH_PASSWORD', false)
    }

    if (state.loggedInWithPrism) {
      commit('SET_LOGGED_IN_WITH_PRISM', false)
    }

    // Because vuex-persistence events are asynchronous, they're probably not even done yet
    // This won't remove the localStorage key like you expect it, it will only clear existing data
    // But clearState is already supposed to do that
    //
    //const activeUniqueIdentifier = await dispatch('removeActiveUniqueIdentifier'

    //if (activeUniqueIdentifier) {
    //  await dispatch('clearPersistedState', activeUniqueIdentifier, {root: true})
    //}

    return Promise.resolve()
  },

  async setPosthogIdentity({state}) {
    const user = state.user
    const identityId = user.id
    const actorId = user.identity_allow_auth_role_actor_id
    const username = `${user.identity_type}/${user.username}`

    const assignedEmployeeRole = user.accounts
      ?.flatMap(account => account.actors)
      ?.flatMap(actor => actor.roles)
      ?.filter(role_values => role_values[1] == 'assigned-employee')
      ?.map(array => _.fromPairs(_.chunk(array, 2)))
      ?.first()

    const clientId = assignedEmployeeRole?.client_id
    const employeeId = assignedEmployeeRole?.employee_id
    const clientName = assignedEmployeeRole?.client_name
    const env = assignedEmployeeRole?.env

    const employeeIdentifier = `${env}/${clientId}/${employeeId}`

    posthog.register({prismEnv: env, ...(employeeIdentifier ? {employeeIdentifier} : {})})

    posthog.identify(
      identityId,
      {identityId, actorId, clientId, employeeId, clientName, username},
    )
  },

  async clearState( { commit, dispatch } ) {

    await dispatch('clearUniqueSignedInIdentifier')

    commit('CLEAR_STATE')

    return Promise.resolve()
  },
}

const mutations = {

  USER (state, user) {
    state.user = user
  },

  IDENTITYID (state, identityId) {
    state.identityId = identityId
  },

  ACTORID (state, actorId) {
    state.actorId = actorId
  },

  ACCOUNTID (state, actorId) {
    state.accountId = actorId
  },

  PEOID (state, peoId) {
    state.peoId = peoId
  },

  SSOKEY (state, ssoKey) {
    state.ssoKey = ssoKey
  },

  SET_LOGGED_IN_WITH_GOOGLE(state, boolean) {
    state.loggedInWithGoogle = boolean
  },

  SET_LOGGED_IN_WITH_MICROSOFT_USERNAME(state, username) {
    if (username) {
      state.loggedInWithMicrosoft = true
      state.loggedInMicrosoftUsername = username
    }
    else {
      state.loggedInWithMicrosoft = false
      state.loggedInMicrosoftUsername = undefined
    }
  },

  SET_LOGGED_IN_WITH_PASSWORD(state, boolean) {
    state.loggedInWithPassword = boolean
  },

  SET_LOGGED_IN_WITH_PRISM(state, boolean) {
    state.loggedInWithPrism = boolean
  },

  SET_SIGNOUT_TRIGGERED(state, boolean) {
    state.signoutTriggered = boolean
  },

  SET_FOUND_EMPLOYEE_DIGESTS(state, foundEmployeeDigests) {
    state.foundEmployeeDigests = foundEmployeeDigests
  },

  REMOVE_FOUND_EMPLOYEE_DIGESTS(state) {
    state.foundEmployeeDigests = []
  },

  REMOVE_TAKEN_USERNAMES(state) {
    state.takenUsernames = {}
  },

  REMOVE_TAKEN_USERNAME(state, username) {
    state.takenUsernames = {...state.takenUsernames, [username]: undefined}
  },

  SET_TAKEN_USERNAME(state, username) {
    state.takenUsernames = {...state.takenUsernames, [username]: true}
  },

  SET_REQUESTED_ROUTE_BEFORE_SIGNIN(state, route) {
    state.requestedRouteBeforeSignIn = route
  },

  REMOVE_REQUESTED_ROUTE_BEFORE_SIGNIN(state) {
    state.requestedRouteBeforeSignIn = undefined
  },

  CLEAR_STATE(state) {
    state.user = undefined

    state.accountId = undefined
    state.actorId = undefined
    state.identityId = undefined
    state.roleNames = {}

    state.peoId = undefined
    state.ssoKey = undefined

    state.requestedRouteBeforeSignIn = undefined

    state.foundEmployeeDigests = []
    state.takenUsernames = {},
    state.signoutTriggered = false

    state.loggedInWithGoogle = false
    state.loggedInWithMicrosoft = false
    state.loggedInWithPassword = false
    state.loggedInWithPrism = false

    state.loggedInMicrosoftUsername = undefined
  },

}

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  actions,
  mutations,
}
