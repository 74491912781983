
const constants = Object.freeze({
  roleNames: {
    manageUser:     "manage-user",
    manageContacts: "contacts",
    outboundSSO:    "sso-outbound",
    kronos:         "kronos",
    apiSpec:        "api-spec",
    prismCache:     "prism-cache",
  }
})

export default constants
