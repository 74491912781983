import _ from 'lodash'

const getters = {
  _actor: (state, getters, rootState, rootGetters) => rootGetters['Account/_account']?.actors?.find(a => a.id == rootState.Auth.actorId),

  id: (state, getters) => getters._actor?.id,

  roles: (state, getters) => getters._actor?.roles,

  employee: (state, getters) => _.fromPairs(_.chunk(getters.roles?.find(role => role[1] == 'assigned-employee'), 2)),

  clientId: (u, getters) => getters.employee?.client_id,
  clientName: (u, getters) => getters.employee?.client_name,
}

const actions = {
  clearState: () => Promise.resolve(),
}

export default {
  namespaced: true,
  getters,
  actions,
}
