<template>
  <router-view />
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'
import { mapGetters } from 'vuex'

export default {

  metaInfo: {
    title: 'Green',
    meta: [
      {
        vmid: 'viewport',
        name: 'viewport',
        content: navigator.userAgent.match(/mobile/i)
          ? 'width=device-width, initial-scale=1'
          : 'width=1280'
      },
    ]
  },

  computed: {
    ...mapGetters([
      'isAuthenticated'
    ]),
  },

  created() {
    //window.dispatchEvent(new Event(window.navigator.onLine ? 'online' : 'offline'))
  }
};
</script>

<style lang="scss" scoped>
$backgroundColor: #faf9f5;
$dirtyInputColor: #faf9f5;

dirtyInput {
  background-color: $dirtyInputColor;
}
</style>
