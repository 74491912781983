import _ from 'lodash'

const getters = {
  _account: (state, getters, rootState) => rootState.Auth.user?.accounts?.find(a => a.id == rootState.Auth.accountId),

  id: (state, getters) => getters._account.id,

  name: (state, getters) => getters._account?.name,

  initials: (state, getters) => getters.name?.toUpperCase().match(/\b(\w)/g).join(''),

  employees: (state, getters) => { //, rootState) => {
    return getters._account?.actors
    ?.flatMap(actor => actor.roles)
    .filter(role => role[1] == 'assigned-employee')
    .map(array => _.fromPairs(_.chunk(array, 2)))
  }

  //clients: (state, getters) => Array.from(new Set(getters.employees)),
}

const actions = {
  clearState: () => Promise.resolve(),
}

export default {
  namespaced: true,
  getters,
  actions,
}
