import axios from 'axios';

const api = axios.create({
  withCredentials: true,
  headers: {
    'Accept':       'application/json',
    'Content-Type': 'application/json',
  }
})

window.axios = api

export default {

  setInvalidAuthInterceptor(cb) {
    window.axios.interceptors.response.use(
      function(response) {
        const jwt = (response.headers.authorization || '').replace('Bearer ', '')

        if (jwt) this.setJwtInAuthorizationHeader(jwt)

        return response
      }.bind(this),
      async error => {
        if (error.response && 401 === error.response.status) {
          await cb({error})
        }
        throw error;
      }
    )
  },

  setApiUrl(url) {
    api.defaults.baseURL = url
  },

  //setActorId(actorId) {
    //api.defaults.headers.common['As-Actor-Id'] = actorId
  //},

  setUseCookieAuth(useCookieAuth) {
    api.defaults.headers.common['Use-Cookie-Auth'] = useCookieAuth
  },

  setJwtInAuthorizationHeader(jwt) {
    api.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
  },

  authPath: '/auth',

  async postAuth(credentials) {
    const res = await api.post(this.authPath, credentials)
    return res
  },

  async signOut() {
    return (await api.post('/signout')).data
  },

  async getOrphanedUserLayers(actorId) {
    return await api.get(
      '/users/orphaned-layers',
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async getEmployee(actorId, {assignedEmployeeRole}) {
    const r = assignedEmployeeRole
    return await api.get(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async saveEmployee(actorId, {assignedEmployeeRole, employeeInfo}) {
    const r = assignedEmployeeRole
    return await api.patch(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }`,
      employeeInfo,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async getBenefitPlans(actorId, {assignedEmployeeRole}) {
    const r = assignedEmployeeRole
    return await api.get(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }/benefits`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async getPaycard(actorId, {assignedEmployeeRole}) {
    const r = assignedEmployeeRole

    const startDate = new Date()
    const daysBack = 365

    return await api.get(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }/paycard-balance`,
      {
        params: { startDate, daysBack, env: r.env },
        headers: { 'As-Actor-Id': actorId }
      },
    )
  },

  async getPayrollHistory(actorId, {assignedEmployeeRole}) {
    const r = assignedEmployeeRole

    let startDate = new Date()
    startDate.setDate( startDate.getDate() - 400 )
    let stopDate = new Date()

    return await api.get(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }/payroll-history`,
      {
        params: { startDate, stopDate, env: r.env },
        headers: { 'As-Actor-Id': actorId }
      },
    )
  },

  async getPayrollStub(actorId, {assignedEmployeeRole, voucherId}) {
    const r = assignedEmployeeRole

    return await api.get(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }/payroll-stub/${ voucherId }`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async findEmployeeDigests(identifiers) {
    return (await api.get('/employees', { params: identifiers }))
  },

  async findUsername(username) {
    return (await api.get('/registeredusers', { params: {username: username} })).data
  },

  async getSettings() {
    return (await api.get('/settings/green')).data
  },

  async saveSettings(settings) {
    return (await api.patch('/settings/green', settings)).data
  },

  async savePendingUpdate(actorId, {assignedEmployeeRole, update}) {
    const r = assignedEmployeeRole
    return await api.post(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }/pending-updates`,
      update,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async getPendingUpdates(actorId, {assignedEmployeeRole}) {
    const r = assignedEmployeeRole

    return await api.get(
     `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }/pending-updates`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async confirmPendingUpdate(actorId, {beacon}) {
    return await api.put(
      `/confirm-pending-update/${beacon}`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async cancelPendingUpdates(actorId, {assignedEmployeeRole, fieldName}) {
    const r = assignedEmployeeRole

    return await api.delete(
      `/clients/${ r.env }/${ r.client_id }/employees/${ r.employee_id }/pending-updates/${fieldName}`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async getProviderData(actorId, {assignedEmployeeRole, providerName}) {
    const r = assignedEmployeeRole

    return await api.get(
      `/providers/${providerName}/clients/${ r.client_id }/employees/${ r.employee_id }`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async createUser(options) {
    return api.post('/registeredusers', options)
  },

  async removeUser(actorId, {accountId}) {
    return await api.delete(
      `/users/${accountId}`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async confirmEmployeeRegistration() {
    return await api.post('/employee-confirmations')
  },

  async verifyEmployeeRegistrationConfirmationCode(code) {
    return await api.patch(`/employee-confirmations/${code}`)
  },

  async sendConfirmationCode(actorId, name) {
    return await api.post(
     '/confirmation-codes',
      { name: name },
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async verifyConfirmationCode(actorId, code) {
    return await api.put(
      `/confirmation-codes/${code}`,
      {},
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async users(actorId, {accountId}) {
    return await api.get(
      accountId ? `/users/${accountId}` : '/users',
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async roleNames(actorId) {
    return await api.get(
      '/role-names',
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async enableDisableIdentity(actorId, {identityId, identityActorId, enable}) {
    return await api.patch(
      `/identities/${identityId}`,
      { identityActorId, disabled: !enable },
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async updateRole(actorId, {roleActorId, roleId, enable, ...updates}) {
    return await api.patch(
      `/users/${roleActorId}/roles/${roleId}`,
      { disabled: !enable, ...updates },
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async removeRole(actorId, {roleActorId, roleId}) {
    return await api.delete(
      `/users/${roleActorId}/roles/${roleId}`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async updateAccount(actorId, {accountId, accountName}) {
    return await api.patch(
      `/users/${accountId}`,
      { accountName },
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async clientNameSearch(actorId, {search}) {
    return await api.get(
      `/clients?search=${search}`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async contactSearch(actorId, {search}) {
    let params = new URLSearchParams()
    params.append("search", search)

    return api.get(
      '/contacts',
      {
        params,
        headers: { 'As-Actor-Id': actorId }
      },
    )
  },

  async getClientList(actorId) {
    return await api.get(
      '/clients?offset=0&limit=10000',
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async clientContacts(actorId, {env, clientId}) {
    return await api.get(
      `/clients/${env}/${clientId}/contacts`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  clientContactsCsv(actorId, {env, clientId}) {
    return api.get(
      `/clients/${env}/${clientId}/contacts.csv`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async getContactProperties(actorId) {
    return await api.get(
      '/contacts/properties',
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async getContactPropertyMethods(actorId, {propertyId}) {
    return await api.get(
      `/contacts/properties/${propertyId}/methods`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async saveContact(actorId, {contact}) {
    return await api.put(
      `/contacts/${contact.id}`,
      contact,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async addContact(actorId, {contact}) {
    return await api.post(
      `/contacts`,
      contact,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async removeContact(actorId, {contactId}) {
    return await api.delete(
      `/contacts/${contactId}`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async addRole(actorId, role) {
    return await api.post(
      `/users/${role.actor_id}/roles`,
      role,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async addAccountIdToActor(actorId, {accountId, userActorId}) {
    return await api.post(
      `/users/${accountId}/actors/${userActorId}`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async removeAccountIdFromActor(actorId, {accountId, userActorId}) {
    return await api.delete(
      `/users/${accountId}/actors/${userActorId}`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async addIdentity(actorId, identity) {
    return await api.post(
      `/identities`,
      identity,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async removeIdentity(actorId, {identityId}) {
    return await api.delete(
      `/identities/${identityId}`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async kronosCompanies(actorId) {
    return await api.get(
      `/kronos/companies`,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async saveKronosCompany(actorId, {company}) {
    return await api.put(
      `/kronos/companies/${company.short_name}`,
      company,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async kronosSettings(actorId) {
    return await api.get(
      `/kronos`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async saveKronosSettings(actorId, {settings}) {
    return await api.put(
      `/kronos`,
      settings,
      { headers: { 'As-Actor-Id': actorId } },
    )
  },

  async queueKronosSync(actorId, {shortName, employeeIds}) {
    return await api.post(
      `/kronos/sync`,
      { shortName, employeeIds },
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async elasticsearch_jobs(actorId, {category, startDate, stopDate}) {
    return await api.get(
      `/logs/jobs`,
      {
        params: { category, startDate, stopDate },
        headers: { 'As-Actor-Id': actorId }
      },
    )
  },

  async elasticsearch_job_logs(actorId, {jobId, ...params}) {
    return await api.get(
      `/logs/jobs/${jobId}`,
      {
        params,
        headers: { 'As-Actor-Id': actorId }
      },
    )
  },

  getOpenApiSpec() {
    return api.get(`/openapi`)
  },

  async getBenefitPlanSBCDownloadUrl(actorId, { assignedEmployeeRole, planId }) {
    const r = assignedEmployeeRole

    return await api.get(
      `/benefits/${r.env}/${planId}/sbc`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async getZywaveLms(actorId, {assignedEmployeeRole}) {
    const r = assignedEmployeeRole
    return await api.get(
      `/zywave/lms/${ r.client_id }/${ r.employee_id }`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async getAuthCookieZywaveLmsRedirectUrl(actorId) {
    return await api.get(
      `/authcookie/zywavelms/url`,
      { headers: { 'As-Actor-Id': actorId } }
    )
  },

  async getPostereliteEPosters(actorId, { zip }) {
    return await api.get(
      '/poster/eposters',
      {
        headers: { 'As-Actor-Id': actorId },
        params: { zip },
      }
    )
  },

  async getPostereliteEPostersUrl(actorId, params) {
    return await api.get(
      '/poster/eposters/url',
      {
        headers: { 'As-Actor-Id': actorId },
        params,
      }
    )
  },
}
