import _ from 'lodash'

let throttledFunctions = {}

const Throttle = {

  install(Vue) { // , options) {

    Vue.prototype.throttleAndDebounce = (name, func, time, options, args) => {
      console.log(throttledFunctions, name, args)
      //if (!throttledFunctions[name]) {
      //  console.log('jssigning throttle', name)
      //}

      throttledFunctions[name] ??= _.debounce(_.throttle(func, time, options), time)

      throttledFunctions[name](...(args ?? []))
    }

    Vue.prototype.removeThrottleAndDebounce = (name) => {
      console.log('removing throttled', name)
      delete throttledFunctions[name]
    }
  },
}

export default Throttle
