import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'
//import _ from 'lodash'

const state = {
  settings: {}
}

const actions = {

  async getSettings( { commit } ) {
    const data = await AdvanstaffAPI.getSettings()
    commit('SET_SETTINGS', data)
    return Promise.resolve(data)
  },

  async saveSettingsToServer( { state, commit } ) {
    const data = await AdvanstaffAPI.saveSettings(state.settings)
    commit('SET_SETTINGS', data)
    return Promise.resolve(data)
  },

  clearState: ({ commit }) => {

    commit('CLEAR_STATE')

    return Promise.resolve()
  },
}

const mutations = {

  SET_SETTINGS(state, settings) {
    state.settings = settings
  },

  REMOVE_SETTINGS(state) {
    state.settings = {}
  },

  CLEAR_STATE(state) {
    state.settings = {}
  }
}

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state,
  actions,
  mutations,
}
