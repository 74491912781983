import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'
import Console from '@/Console'

const state = {
  employeeInfo: {},
  benefitPlans: {},
  paycard: {},
  payrollHistory: {},
  pendingUpdates: {},
  zywaveProviderData: {},
  zywaveLms: {},
  postereliteEPosters: {},
}

const getters = {
  actorId: (state, getters, rootState) => rootState.Auth.actorId,

  assignedEmployeeRole: (state, getters, rootState, rootGetters) => rootGetters['Actor/employee'],
  employeeInfo: (state, getters) => state.employeeInfo[getters.actorId] ?? {},
  employeeInfoCopy: (state, getters) => () => JSON.parse(JSON.stringify(state.employeeInfo[getters.actorId] ?? {})),

  hasAddress: (state, getters) => (getters.employeeAddress?.city ?? getters.employeeAddress?.state) != null,
  employeeAddress: (state, getters) => state.employeeInfo[getters.actorId]?.address ?? {},
  hasWorkAddress: (state, getters) => (getters.employeeAddress?.city ?? getters.client?.address?.state) != null,
  employeeWorkAddress: (state, getters) => state.employeeInfo[getters.actorId]?.client?.address ?? {},
  homeLocationZip: (state, getters) => state.employeeInfo[getters.actorId]?.client?.homeLocationZip,

  benefitPlans: (state, getters) => state.benefitPlans[getters.actorId] ?? [],

  paycard: (state, getters) => state.paycard[getters.actorId] ?? {},

  zywaveProviderData: (state, getters) => state.zywaveProviderData[getters.actorId] ?? {},

  zywaveLms: (state, getters) => state.zywaveLms[getters.actorId],

  availableEPosters: (state, getters) => state.postereliteEPosters[getters.actorId],

  payrollHistory: (state, getters) => state.payrollHistory[getters.actorId] ?? {},
  //{
  //  let payrollHistory = { ...state.payrollHistory[getters.actorId] ?? {} }
  //  //payrollHistory.history = payrollHistory.history?.map(pay => { pay.deductionAmount = pay.deductionAmount + pay.otherDeductionAmount; return pay })
  //  return payrollHistory
  //},

  hasPayrollPaidTimeOff: (state, getters) => (getters.payrollHistory.paidTimeOff || []).length >= 1,

  hasDirectDepositAccounts: (state, getters) => {
    //return Array.isArray(getters.employeeInfo.directDeposits)
    return (getters.employeeInfo.directDeposits || []).length > 0
  },

  hasPaycard: (state, getters) => {
    return getters.employeeInfo.hasPaycard
    //const obj = getters.paycard
    //return obj.constructor === Object && Object.keys(obj).length >= 1;
  },

  hasPayrollHistory: (state, getters) => {
    return (getters.payrollHistory.history ?? []).length > 0
  },

  hasPhone: state => (state.employee?.homePhone ?? state.employee?.mobilePhone) != null,
  homePhone: (state, getters) => state.employeeInfo[getters.actorId]?.homePhone?.replace(/\D/g,''),
  mobilePhone: (state, getters) => state.employeeInfo[getters.actorId]?.mobilePhone?.replace(/\D/g, ''),

  hasEmail: state => (state.employee?.homePhone ?? state.employee?.mobilePhone) != null,
  email: (state, getters) => state.employeeInfo[getters.actorId]?.email,
  workEmail: (state, getters) => state.employeeInfo[getters.actorId]?.workEmail,

  pendingUpdates: (state, getters) => state.pendingUpdates[getters.actorId] ?? [],
  pendingUpdatesForName: (state, getters) => name => getters.pendingUpdates?.filter(update => update.name == name),
  hasPendingUpdateForName: (state, getters) => (name) => getters.pendingUpdatesForName(name)?.length > 0 ?? false,

  ePosters: (state, getters) => state.postereliteEPosters[getters.actorId],
}

const actions = {

  async getEmployee ( { commit, getters } ) {
    try {
      const assignedEmployeeRole = getters.assignedEmployeeRole
      const currentActorId = getters.actorId

      const data = (await AdvanstaffAPI.getEmployee(currentActorId, {assignedEmployeeRole})).data
      if (data) commit('SET_EMPLOYEE_INFO', {actorId: assignedEmployeeRole.actor_id, data: data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve employee info', error)
    }
  },

  async savePendingUpdate({ commit, getters }, update) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.employee

      const data = (await AdvanstaffAPI.savePendingUpdate(currentActorId, {assignedEmployeeRole, update})).data
      if (data) commit('ADD_PENDING_UPDATE', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve(data)
    }
    catch (error) {
      Console.error('Could not save pending update', error)
    }
  },

  async saveEmployee ( { commit, getters }, employeeInfo) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.saveEmployee(currentActorId, {assignedEmployeeRole, employeeInfo})).data
      if (data) commit('SET_EMPLOYEE_INFO', {actor_id: assignedEmployeeRole.actor_id, data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve save employee info to server', error)
    }
  },

  async getBenefitPlans ( { commit, getters } ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getBenefitPlans(currentActorId, {assignedEmployeeRole})).data
      if (data) commit('SET_BENEFIT_PLANS', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve(data)
    }
    catch (error) {
      Console.error('Could not retrieve benefit plans', error)
    }
  },

  async getBenefitPlanSBCDownloadUrl ( { getters }, { planId } ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getBenefitPlanSBCDownloadUrl(currentActorId, {assignedEmployeeRole, planId})).data
      return data
    }
    catch (error) {
      Console.error('Could not retrieve benefit plans', error)
    }
  },

  async getPaycard ( { commit, getters } ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getPaycard(currentActorId, {assignedEmployeeRole})).data
      if (data) commit('SET_PAYCARD', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve paycard', error)
    }
  },

  async getPayrollHistory ( { commit, getters } ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getPayrollHistory(currentActorId, {assignedEmployeeRole})).data
      if (data) commit('SET_PAYROLL_HISTORY', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve paycard', error)
    }
  },

  async getPayrollStub ( { getters }, voucherId ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getPayrollStub(currentActorId, {assignedEmployeeRole, voucherId})).data
      return Promise.resolve(data)
    }
    catch (error) {
      Console.error('Could not retrieve paycard', error)
    }
  },

  async getPendingUpdates ( { commit, getters } ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getPendingUpdates(currentActorId, {assignedEmployeeRole})).data
      if (data) commit('SET_PENDING_UPDATES', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve paycard', error)
    }
  },

  async confirmPendingUpdate ( { getters }, beacon ) {
    const currentActorId = getters.actorId

    return await AdvanstaffAPI.confirmPendingUpdate(currentActorId, {beacon})
  },

  async cancelPendingUpdates ( { getters }, fieldName ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      return (await AdvanstaffAPI.cancelPendingUpdates(currentActorId, {assignedEmployeeRole, fieldName})).data
    }
    catch (error) {
      Console.error('Could not cancel pending updates for fieldName', fieldName)
    }
  },

  async getZywaveProviderData ( { getters, commit } ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const providerName = 'zywave'

      const data = (await AdvanstaffAPI.getProviderData(currentActorId, {assignedEmployeeRole, providerName})).data
      if (data) commit('SET_ZYWAVE_PROVIDER_DATA', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve zywave provider data', error)
    }
  },

  async getZywaveLms ( { getters, commit } ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getZywaveLms(currentActorId, {assignedEmployeeRole})).data
      if (data) commit('SET_ZYWAVE_LMS', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve zywave lms', error)
    }
  },

  async getAuthCookieZywaveLmsRedirectUrl ( { getters } ) {
    try {
      const currentActorId = getters.actorId
      const data = (await AdvanstaffAPI.getAuthCookieZywaveLmsRedirectUrl(currentActorId)).data
      return Promise.resolve(data.url)
    }
    catch (error) {
      Console.error('Could not retrieve auth cookie zywave lms redirect url', error)
    }
  },

  async getEPosters ( { getters, commit }, params ) {
    try {
      const currentActorId = getters.actorId
      const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getPostereliteEPosters(currentActorId, params)).data
      if (data) commit('SET_POSTERELITE_EPOSTERS', {actorId: assignedEmployeeRole.actor_id, data})
      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not retrieve posterelite eposters', error)
    }
  },

  async getPostereliteEPostersUrl ( { getters }, params ) {
    try {
      const currentActorId = getters.actorId
      // const assignedEmployeeRole = getters.assignedEmployeeRole

      const data = (await AdvanstaffAPI.getPostereliteEPostersUrl(currentActorId, params)).data
      return data
    }
    catch (error) {
      Console.error('Could not retrieve posterelite eposters url', error)
    }
  },

  loadEmployee ({ dispatch, getters }) {
    let promises = [
      dispatch('getEmployee'),
      dispatch('getBenefitPlans'),
      dispatch('getPendingUpdates'),
      dispatch('User/getRoleNames', null, {root: true}),
      dispatch('getPayrollHistory'),
      //dispatch('getZywaveProviderData').catch(error => Console.error('silent fail of getZywaveProviderData', error) ),
    ]

    return Promise.all(promises)

    .then((results) => getters.hasPaycard ? dispatch('getPaycard') : results)
  },

  clearState: ({ commit }) => {

    commit('CLEAR_STATE')

    //_.forEach(_.keys(state), function(key) {
      //state[key] = {}
    //})

    return Promise.resolve()
  }
}

const mutations = {

  SET_EMPLOYEE_INFO(state, {actorId, data}) {
    state.employeeInfo = { ...state.employeeInfo, [actorId]: data}
  },

  SET_BENEFIT_PLANS(state, {actorId, data} ) {
    state.benefitPlans = { ...state.benefitPlans, [actorId]: data}
  },

  SET_PAYCARD(state, {actorId, data}) {
    state.paycard = { ...state.paycard, [actorId]: data}
  },

  SET_PAYROLL_HISTORY(state, {actorId, data}) {

    for (let pay of (data.history || [])) {
      pay.deductionAmount = pay.deductionAmount + pay.otherDeductionAmount
    }

    state.payrollHistory = { ...state.payrollHistory, [actorId]: data}
  },

  SET_PENDING_UPDATES(state, {actorId, data}) {
    state.pendingUpdates = { ...state.pendingUpdates, [actorId]: data}
  },

  SET_ZYWAVE_PROVIDER_DATA(state, {actorId, data}) {
    state.zywaveProviderData = { ...state.zywaveProviderData, [actorId]: data}
  },

  ADD_PENDING_UPDATE(state, {actorId, data}) {
    const actorPendingUpdates = state.pendingUpdates[actorId] ?? []
    actorPendingUpdates.push(data)

    state.pendingUpdates = { ...state.pendingUpdates, [actorId]: actorPendingUpdates }
  },

  REMOVE_EMPLOYEE_INFO(state, actorId) {
    state.employeeInfo[actorId] = {}
  },

  REMOVE_BENEFIT_PLANS(state, actorId) {
    state.benefitPlans[actorId] = []
  },

  REMOVE_PAYCARD(state, actorId) {
    state.paycard[actorId] = {}
  },

  SET_ZYWAVE_LMS(state, {actorId, data}) {
    state.zywaveLms = { ...state.zywaveLms, [actorId]: data}
  },

  SET_POSTERELITE_EPOSTERS(state, {actorId, data}) {
    state.postereliteEPosters = { ...state.postereliteEPosters, [actorId]: data}
  },

  REMOVE_ZYWAVE_LMS(state, actorId) {
    state.zywaveLms[actorId] = {}
  },

  CLEAR_STATE(state) {
    state.employeeInfo = {}
    state.benefitPlans = {}
    state.paycard = {}
    state.payrollHistory = {}
    state.pendingUpdates = []
    state.zywaveProviderData = {}
    state.zywaveLms = {}
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
