import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'
import _ from 'lodash'
import Console from '@/Console'

const state = {
  users: {},
  roleNames: {},
  orphanedUserLayers: {},

  // Temporary storage for creating a new user
  // before it's sent to the server
  newUser: {},
}

const getters = {
  actorId: (state, getters, rootState) => rootState.Auth.actorId,

  users: (state, getters) => state.users[getters.actorId] ?? [],
  userByAccountId: (state, getters) => (accountId) => getters.users.find(user => user.account_id == accountId),
  canUserSignIn: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.actors.flatMap(a => a.roles).filter(a => a.name == 'allow-auth').some(r => r.enabled),
  identitiesByAccountId: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.identities.map(identityArray => Object.fromEntries(_.chunk(identityArray, 2))),
  actorsByAccountId: (state, getters) => (accountId) => getters.userByAccountId(accountId)?.actors,
  roleNames: (state, getters) => state.roleNames[getters.actorId] ?? [],
  hasRoleName: (state, getters) => (predicateRoleName) => getters.roleNames.some(roleName => roleName == predicateRoleName),
  orphanedUserLayers: (state, getters, rootState) => state.orphanedUserLayers[rootState.Auth.actorId] ?? {},

  identityForAccountIdAndIdentityId: (state, getters) => (accountId, identityId) => getters.identitiesByAccountId(accountId)?.find(i => i.id == identityId),

  assignedEmployeesForAccountId: (state, getters) => (accountId) => {
    const account = getters.userByAccountId(accountId)
    const roles = account.actors.flatMap(actor => actor.roles)
    const assignedEmployees = roles
      .filter(role => role.name == 'assigned-employee')
      .map(role => Object.fromEntries(_.chunk(role.values, 2)))

    return assignedEmployees
  },

  assignedEmployeesForAccountIdAndActorId: (state, getters) => (accountId, actorId) => {
    const actorRoles = getters.actorsByAccountId(accountId)?.find(a => a.id == actorId)?.roles

    if (!actorRoles) return undefined

    const assignedEmployees = actorRoles
      .filter(role => role.name == 'assigned-employee')
      .map(role => Object.fromEntries(_.chunk(role.values, 2)))

    return assignedEmployees
  },
}

const actions = {

  async getOrphanedUserLayers ( { commit, getters } ) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.getOrphanedUserLayers(currentActorId)).data
    if (data) commit('SET_ORPHANED_USER_LAYERS', {actorId: currentActorId, data: data})
    return Promise.resolve()
  },

  async getUsers ( { commit, getters }, id ) {
    const currentActorId = getters.actorId

    const users = (await AdvanstaffAPI.users(currentActorId, {accountId: id})).data
    const overwriteAll = id ? false : true

    if (users) commit('SET_USERS', {actorId: currentActorId, users, overwriteAll})

    return Promise.resolve(users)
  },

  async removeUser ( { commit, getters }, accountId ) {
    try {
      const currentActorId = getters.actorId

      await AdvanstaffAPI.removeUser(currentActorId, {accountId})

      commit('REMOVE_USER', {actorId: currentActorId, accountId})

      return Promise.resolve()
    }
    catch (error) {
      Console.error('Could not remove user', error)
      return Promise.reject(error)
    }
  },

  async getRoleNames ( { commit, getters } ) {
    const currentActorId = getters.actorId

    const data = (await AdvanstaffAPI.roleNames(currentActorId)).data
    if (data) commit('SET_ROLE_NAMES', {actorId: currentActorId, data: data})
    return Promise.resolve()
  },

  async enableDisableIdentity( { commit, getters }, { identityId, identityActorId, accountId, enable } ) {
    const currentActorId = getters.actorId

    await AdvanstaffAPI.enableDisableIdentity(currentActorId, {identityId, identityActorId, enable})
    const users = await AdvanstaffAPI.users(currentActorId, {accountId})

    commit('SET_USERS', {actorId: currentActorId, users})

    return Promise.resolve()
  },

  async addIdentityAndAuthRoleAndAssociateAccount( { commit, getters }, { accountId, identityActorId, type, username, password, enabled } ) {
    const currentActorId = getters.actorId

    await AdvanstaffAPI.addIdentity(currentActorId, {accountId, identityActorId, type, username, password, enabled})
    const users = await AdvanstaffAPI.users(currentActorId, {accountId})

    commit('SET_USERS', {actorId: currentActorId, users})

    return Promise.resolve()
  },

  async removeIdentity ( { commit, getters }, identity) {
    const currentActorId = getters.actorId

    let removedIdentity = (await AdvanstaffAPI.removeIdentity(currentActorId, {identityId: identity.id})).data
    removedIdentity.enabled = !removedIdentity.disabled // Server uses disabled, client uses enabled
    removedIdentity.account_id = identity.account_id

    commit('REMOVE_IDENTITY', {actorId: currentActorId, identity: removedIdentity})

    return Promise.resolve(removedIdentity)
  },

  async updateRole( { commit, getters }, { accountId, roleActorId, roleId, enable, ...updates } ) {
    const currentActorId = getters.actorId

    let updatedRole = (await AdvanstaffAPI.updateRole(currentActorId, {roleActorId, roleId, enable, ...updates})).data
    updatedRole.account_id = accountId
    updatedRole.enabled = !updatedRole.disabled // Server uses disabled, client uses enabled

    commit('ADD_ROLE', {actorId: currentActorId, role: updatedRole})

    return Promise.resolve
  },

  async removeRole ( { commit, getters }, role) {
    const currentActorId = getters.actorId

    let removedRole = (await AdvanstaffAPI.removeRole(currentActorId, {roleActorId: role.actor_id, roleId: role.id})).data
    removedRole.account_id = role.account_id
    removedRole.enabled = !removedRole.disabled // Server uses disabled, client uses enabled

    commit('REMOVE_ROLE', {actorId: currentActorId, role: removedRole})

    return Promise.resolve()
  },

  async addRole ( { commit, getters }, role) {
    const currentActorId = getters.actorId

    let addedRole = (await AdvanstaffAPI.addRole(currentActorId, role)).data
    addedRole.account_id = role.account_id
    addedRole.enabled = !addedRole.disabled // Server uses disabled, client uses enabled

    commit('ADD_ROLE', {actorId: currentActorId, role: addedRole})

    return Promise.resolve()
  },

  async updateAccount( { commit, getters }, { accountId, accountName } ) {
    const currentActorId = getters.actorId

    await AdvanstaffAPI.updateAccount(currentActorId, {accountId, accountName})
    const users = (await AdvanstaffAPI.users(currentActorId, {accountId})).data

    commit('SET_USERS', {actorId: currentActorId, users})

    return Promise.resolve()
  },

  async removeAccountIdFromActor( { commit, getters }, { accountId, userActorId } ) {
    const currentActorId = getters.actorId

    await AdvanstaffAPI.removeAccountIdFromActor(currentActorId, {accountId, userActorId})

    const users = await AdvanstaffAPI.users(currentActorId, {accountId})

    commit('SET_USERS', {actorId: userActorId, users})

    return Promise.resolve()
  },

  async addAccountIdToActor( { commit, getters }, { accountId, userActorId } ) {
    const currentActorId = getters.actorId

    await AdvanstaffAPI.addAccountIdToActor(currentActorId, {accountId, userActorId})

    const users = await AdvanstaffAPI.users(currentActorId, {accountId})

    commit('SET_USERS', {actorId: currentActorId, users})

    return Promise.resolve()
  },

  async clearState ({ commit }) {
    commit('CLEAR_STATE')

    return Promise.resolve()
  },

  // New User methods

  //addOrUpdateRole( { commit, state }, role ) {
  //  const index = newUser.roles.findIndex(r => r.id == role.id)

  //  if (index == -1) {
  //    // Does not exist already
  //    newUser.roles = {...newUser.roles, role}
  //  }
  //  else {
  //    // Exists already
  //    newUser.roles[0] = role
  //  }
  //},
}

const mutations = {

  //SET_IDENTITY(state, {actorId, identity}) {
  //  // Find identity in state.users
  //  const identityIndex = state.users[actorId]
  //    .identities.findIndex(i => Object.fromEntries(_.chunk(identityArray, 2)).id == identity.id)

  //  state.users[actorId].identities[identityIndex] = identity
  //  state.users = { ...state.users,
  //},

  REMOVE_USER(state, {actorId, accountId}) {
    const users = state.users[actorId].filter(u => u.account_id != accountId)
    state.users = { ...state.users, [actorId]: users }
  },

  ADD_ROLE(state, {actorId, role}) {
    const users = state.users[actorId]
    const user = users.find(u => u.account_id == role.account_id)

    //const actorId = role.actor_id ?? role.object?.user_layers?.[0]?.actor_id

    const actor = user.actors.find(a => a.id == actorId)

    const roleIndex = actor.roles.findIndex(r => r.id == role.id)
    if (roleIndex == -1) {
      // Push new role
      //actor.roles.push(role)
      actor.roles.splice(-1, 0, role)
    }
    else {
      // Replace existing role with same role.id
      actor.roles.splice(roleIndex, 1, role)
    }

    state.users = { ...state.users, [actorId]: users}
  },

  REMOVE_ROLE(state, {actorId, role}) {
    const users = state.users[actorId]
    const user = users.find(u => u.account_id == role.account_id)
    const actor = user.actors.find(a => a.id == role.actor_id)

    const roleIndex = actor.roles.findIndex(r => r.id == role.id)
    if (roleIndex != -1) {
      actor.roles.splice(roleIndex, 1)
    }

    state.users = { ...state.users, [actorId]: users}
  },

  REMOVE_IDENTITY(state, {actorId, identity}) {
    const users = state.users[actorId]
    const user = users.find(u => u.account_id == identity.account_id)

    // TODO/FIXME: Why are identities stored as arrays, not objects?
    const identities =  user.identities.map(identityArray => Object.fromEntries(_.chunk(identityArray, 2)))

    const identityIndex = identities.findIndex(i => i.id == identity.id)
    if (identityIndex != -1) {
      user.identities.splice(identityIndex, 1)
    }

    state.users = { ...state.users, [actorId]: users}
  },

  SET_ACTOR(state, {actorId, actor}) {
    console.log('SET ', {actorId, actor})
    //const users = state.users[actorId]
    //const user = users.find(u => u.account_id == role.account_id)
    //const actor = user.actors.find(a => a.id == role.actor_id)

    //const roleIndex = actor.roles.findIndex(r => r.id == role.id)
    //if (roleIndex == -1) {
    //  // Push new role
    //  //actor.roles.push(role)
    //  actor.roles.splice(-1, 0, role)
    //}
    //else {
    //  // Replace existing role with same role.id
    //  actor.roles.splice(roleIndex, 1, role)
    //}

    //state.users = { ...state.users, [actorId]: users}
  },

  SET_USERS(state, {actorId, users, overwriteAll}) {

    const actorUsersInStore = state.users[actorId]

    if (overwriteAll) {
      state.users = { ...state.users, [actorId]: users }
      return
    }

    for (const user of users) {
      const accountId = user.account_id
      const userIndex = actorUsersInStore.findIndex(u => u.account_id == accountId)

      actorUsersInStore.splice(userIndex, userIndex == -1 ? 0 : 1, user)
    }

    state.users = { ...state.users, [actorId]: actorUsersInStore }
  },

  SET_ROLE_NAMES(state, {actorId, data}) {
    state.roleNames = { ...state.roleNames, [actorId]: data}
  },

  SET_ORPHANED_USER_LAYERS(state, {actorId, data}) {
    state.orphanedUserLayers = { ...state.orphanedUserLayers, [actorId]: data}
  },

  CLEAR_STATE(state) {
    state.users = {}
    state.roleNames = {}
    state.orphanedUserLayers = {}
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
