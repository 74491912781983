//import _ from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/store/modules/auth.js'
import Account from '@/store/modules/account.js'
import Actor from '@/store/modules/actor.js'
import Settings from '@/store/modules/settings.js'
import Employee from '@/store/modules/employee.js'
import User from '@/store/modules/user.js'
import Client from '@/store/modules/client.js'
import ActiveActorPlugin from '@/store/plugins/ActiveActor.js'
import AdvanstaffAPI from '@/services/AdvanstaffAPI.js'

Vue.use(Vuex)

export function createStore() {

  const store = new Vuex.Store({

    strict: process.env.NODE_ENV !== 'production',

    actions: {

      openApiSpec() {
        return AdvanstaffAPI.getOpenApiSpec().then(r => r.data)
      },

      async clearState ({ commit, dispatch }) {

        // Clear state from this module
        commit('CLEAR_STATE')

        // Clear state from submodules
        return Promise.all([
          dispatch('Employee/clearState', undefined, {root: true}),
          dispatch('User/clearState',     undefined, {root: true}),
          dispatch('Auth/clearState',     undefined, {root: true}),
          dispatch('Client/clearState',   undefined, {root: true}),
          dispatch('Settings/clearState', undefined, {root: true}),
        ])
      }
    },

    mutations: {
      CLEAR_STATE () {
      },
    },

    plugins: [
      ActiveActorPlugin
    ],

    modules: {
      Auth,
      Actor,
      Account,
      Settings,
      Employee,
      User,
      Client,
    }
  })

  return store
}
