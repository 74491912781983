
// Verify VUE_APP_* expected vars
//if (!process.env.VUE_APP_API_BASE) console.error('api base not in configuraiton') // eslint-disable-line no-console

import Vue from 'vue'
import VueMeta from 'vue-meta'
import EdgeCheck from 'vue-edge-check'
import GAuth from 'vue-google-oauth2'
import { VueMaskDirective } from 'v-mask'
import Throttle from '@/plugins/throttle'
import Formatter from '@/plugins/formatter'
import { createRouter }  from '@/router/index.js'
import { createStore }   from '@/store/index.js'
import { createVuetify } from './plugins/vuetify'
import App from '@/App.vue'
import posthog from 'posthog-js'

posthog.init(`phc_Rgh0UbwQ3SfpMaM21Jcby96RpbIGpzZiYrTCYdyia3B`, {
  api_host: 'https://ph.advanstaff.com',
  // featureFlags: {
  //   dev: process.env.NODE_ENV == 'development'
  // }
})

posthog.register_once({dev: process.env.NODE_ENV == 'development'})


const gauthOption = {
  clientId: '607507754912-e0lbbtb6q7f0ka7c3nkks8dpvob34ndr.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)

Vue.use(VueMeta)
Vue.use(EdgeCheck)
Vue.use(Throttle)
Vue.use(Formatter)

Vue.directive('mask', VueMaskDirective);

if (!Array.prototype.last) { Array.prototype.last = function() { return this[this.length - 1] } }
if (!Array.prototype.first) { Array.prototype.first = function() { return this[0] } }
String.prototype.capitalize = function() { return this.replace(/\b\w/g, function(l){ return l.toUpperCase() }) }
Number.prototype.decimalCount = function () {
  if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0; 
}

Date.prototype.numericDate = function() {
  return this.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
}

// TODO/FIXME Convert to async dynamically retrieved components
// Register role components
for (const name of ['allow-auth', 'manage-user', 'create-user', 'remove-user',
                    'assigned-employee', 'assigned-mss-username', 'employee-fields', 'benefit-fields',
                    'get-provider-data', 'set-provider-data',
                    'sso-outbound','prismhr-events','mailjet-events', 'kronos-events', 'freshdesk-events',
                    'contacts', 'clients', 'api-spec', 'kronos', 'logs', 'zywave', 'prism-cache']) {
  Vue.component(name, () => import(`@/components/RolePanels/${name}`))
}

Vue.prototype.$userAgent = window.navigator.userAgent
Vue.prototype.$mobileSiteRequested = !!window.navigator.userAgent.match(/mobile/i)
Vue.prototype.$desktopSiteRequested = !window.navigator.userAgent.match(/mobile/i)

export function createApp() {
  const store = createStore()
  const router = createRouter(store)
  const vuetify = createVuetify()

  store.router = router

  const app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  })

  return { app, router, store }
}
