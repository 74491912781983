//import colors from 'vuetify/lib/util/colors'
import { createApp } from './app'

const { app, router } = createApp()

if (process.env.NODE_ENV == 'development') {
  window.app = app
  //console.log('%c%s', 'background: orange; color: white;', `NODE_ENV ${process.env.NODE_ENV}`)
}

//const themes = {
  //online: {
    //primary: colors.green.darken3,
    //secondary: '#FAF9F5',
    //info: colors.yellow.darken1,
    //accent: colors.purple,
    //success: colors.blue,
    //warning: colors.orange,
    //error: colors.red,
    //headerText: colors.white,
  //},
  //offline: {
    //primary: colors.green.darken3,
    //secondary: '#FAF9F5',
    //info: colors.yellow.darken1,
    //accent: colors.purple,
    //success: colors.blue,
    //warning: colors.orange,
    //error: colors.red,
    //headerText: colors.white,
    //primaryOffline: colors.brown.darken2
  //},
//}

window.addEventListener('offline', () => {
  //console.log('OFFLINE SET THEME')
  //app.$vuetify.theme = { ...app.$vuetify.theme, ...themes.offline }
  //app.$vuetify.theme.themes.light = { ...app.$vuetify.theme.themes.light, ...themes.offline }
  //app.$vuetify.theme.themes.dark = { ...app.$vuetify.theme.themes.primary, ...themes.offline }
})

window.addEventListener('online', () => {
  //console.log('ONLINE SET THEME', app.$vuetify.theme)
  //app.$vuetify.theme = { ...app.$vuetify.theme, ...themes.online }
  //app.$vuetify.theme.themes.light = { ...app.$vuetify.theme.themes.light, ...themes.online }
  //app.$vuetify.theme.themes.dark = { ...app.$vuetify.theme.themes.primary, ...themes.online }
  //console.log('ONLINE SET THEME')
  //app.$vuetify.theme.primary = colors.green.darken3
  //app.$vuetify.theme.themes.light.primary = colors.green.darken3
  //app.$vuetify.theme.themes.dark.primary = colors.green.darken3
})

// Force themes to be set
window.dispatchEvent(new Event(window.navigator.onLine ? 'online' : 'offline'))

router.onReady(async () => app.$mount('#app'))
