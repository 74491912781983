
function isInIframe() { return window.self !== window.top }
function isWebkit() { return navigator.vendor.match(/apple/i) }
function isFirefox() { return navigator.vendor.match(/firefox/i) }
function isIos() { return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) }

function hasStorageAccess() {
  if (typeof document.requestStorageAccess !== 'function' || typeof document.hasStorageAccess !== 'function') {
    return false
  }

  return document.requestStorageAccess().then(() => document.hasStorageAccess())
  //.then((hasAccess) => hasAccess ? document.hasStorageAccess() : Promise.reject(false))
}

export { isInIframe, isWebkit, isIos, isFirefox, hasStorageAccess }
//export default { isInIframe, isWebkit, isIos, hasStorageAccess }
